import { Table, Spin } from 'antd';
import { ScanReportTableHeaders } from '../../constants/dashboard';
import axiosInstance, { getApiService } from '../../utility/api';
import toast from 'react-hot-toast';
import { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { AuthContext } from '../../context/AuthContext';
import Filters from '../Dashboard/filters/index';

export default function ScanReport() {
    const context = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({});
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 0,
        total: 0,
    });
    const [sorter, setSorter] = useState({ field: 'id', order: 'ascend' });
    const [loading, setLoading] = useState(false);

    const improveTableData = (tableData) => {
        return tableData.map((row) => ({
            ...row,
            createdAt: row.createdAt ? moment(row.createdAt).format('YYYY-MM-DD') : '',
            updatedAt: row.updatedAt ? moment(row.updatedAt).format('YYYY-MM-DD') : '',
        }));
    };

    const onErrorGetReport = useCallback(
        (error) => {
            console.log(error);
            if (error.message === 'Unauthenticated Context') {
                context.logout();
            } else if (error.message === 'Error: User not found') {
                toast.error(error.message);
            } else {
                toast.error('You have no access to this page');
            }
        },
        [context],
    );

    const createQueryParams = (filters, pagination, sorter) => {
        const { dateFrom, dateTo, kioskID, ...rest } = filters;
        const queryParams = {
            dateFrom: dateFrom ? moment.utc(dateFrom).format('YYYY-MM-DD') : undefined,
            dateTo: dateTo ? moment.utc(dateTo).format('YYYY-MM-DD') : undefined,
            kioskId: kioskID ? kioskID : undefined,
            page: pagination.current,
            sort: sorter.field,
            desk: sorter.order === 'descend' ? 'DESC' : undefined,
        };

        const queryString = Object.keys(queryParams)
            .filter((key) => queryParams[key] !== undefined)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');

        return queryString;
    };

    const fetchData = async (filters, pagination, sorter) => {
        try {
            setLoading(true);
            const queryParams = createQueryParams(filters, pagination, sorter);
            const res = await axiosInstance.get(`/admin/reports/scans?${queryParams}`);
            const tableData = improveTableData(res.data.data);
            setData(tableData);
            setPagination((prev) => ({
                ...prev,
                total: res.data.pagination.totalCount,
                pageSize: res.data.pagination.perPage,
            }));
        } catch (err) {
            onErrorGetReport(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(filter, pagination, sorter);
    }, [filter, pagination.current, sorter]);

    const setDashboardFilter = async (filters) => {
        const updatedFilter = {
            ...filters,
        };
        setFilter(updatedFilter);
        setPagination({
            current: 1,
            pageSize: pagination.pageSize,
            total: 0,
        });
    };

    return (
        <div>
            <Filters setDashboardFilter={setDashboardFilter} disabled={loading} />
            <Spin spinning={loading}>
                <Table
                    dataSource={data}
                    columns={ScanReportTableHeaders}
                    rowKey="id"
                    pagination={{
                        total: pagination.total,
                        pageSize: pagination.pageSize,
                        current: pagination.current,
                        showSizeChanger: false,
                    }}
                    onChange={(pagination, filters, sorter) => {
                        setPagination(pagination);
                        setSorter({
                            field: sorter.field,
                            order: sorter.order,
                        });
                    }}
                />
            </Spin>
        </div>
    );
}
