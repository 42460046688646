import { Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PaymentMethodsPage } from './PaymentMethods/PaymentMethodsPage';
import { Tabs } from 'antd';
import { BillingPage } from './Billing/BillingPage';

const tabs = [
    { label: 'Billing', id: 'billing' },
    { label: 'Payment Methods', id: 'payment-methods' },
];

export const PaymentsPage = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigation />}>
                <Route path="billing" element={<BillingPage />} />
                <Route path="payment-methods" element={<PaymentMethodsPage />} />
                <Route path="*" element={<BillingPage />} />
            </Route>
        </Routes>
    );
};

const Navigation = () => {
    const navigate = useNavigate();
    const params = useParams();
    return (
        <div>
            <Tabs
                className="inline-flex ml-9"
                animated={false}
                tabBarGutter={24}
                tabBarStyle={{ fontFamily: 'Fredoka' }}
                size="large"
                defaultActiveKey="billing"
                activeKey={params?.['*'] === 'payment-methods' ? 'payment-methods' : 'billing'}
                items={tabs.map((tab) => {
                    return {
                        label: tab.label,
                        key: tab.id,
                    };
                })}
                onTabClick={(key) => {
                    navigate(key, { replace: true });
                }}
            />
            <Outlet />
        </div>
    );
};
