import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
    const navigate = useNavigate();
    const goHome = () => {
        navigate("/");
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
    };

    return (
        <div style={containerStyle}>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, we couldn't find the page you are looking for."
                extra={<Button onClick={goHome}>Back to main page</Button>}
            />
        </div>
    );
};

export default ErrorPage;
