import React, { useEffect } from 'react';
import FiltersBaseLine from '../../../components/Common/FiltersBaseLine';
import FeedFiltersDate from '../../Dashboard/filters/date';
import FeedFiltersID from '../../Dashboard/filters/id';

const FilterWrapper = ({ disabled = false, setDashboardFilter }) => {
    const actualFilters = [
        { title: "Date Range", value: "dates", type: "categorical", component: FeedFiltersDate },
        { title: "ID", value: "id", type: "categorical", component: FeedFiltersID },
    ];

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Enter") {
                e.preventDefault();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <div className="filter-wrapper my-10">
            <FiltersBaseLine filtersTypes={actualFilters} isDisabled={disabled} setDashboardFilter={setDashboardFilter} >
                {(slotProps) => (
                    slotProps.filter.component && (
                        <slotProps.filter.component
                            filter={slotProps.filter}
                            name={slotProps.filter.value}
                        />
                    )
                )}
            </FiltersBaseLine>
        </div>
    );
};

export default FilterWrapper;
