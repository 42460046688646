import React, { useContext, useEffect } from 'react';
import Heading from '../components/Heading';
import { useLazyQuery, gql } from '@apollo/client';
import { AuthContext } from '../context/AuthContext';
import OwnersCard from '../components/OwnersCard';
import toast from 'react-hot-toast';
import Payments from './OwnerProfile/Payments';

const GETOWNER = gql`
    query GetOwner {
        user {
            id
            firstname
            lastname
            imageid
            imageUrl
            phonenumber
            phonenumber2
            phonenumber3
            phonetype
            phonetype2
            phonetype3
            email
            email2
            email3
            emailtype
            emailtype2
            emailtype3
            about
            location {
                id
                street1
                street2
                city
                state
                zip
            }
            pets {
                id
                name
                pettype
                microchipnumber
                lost
                lostdescription
                lostlocation
                lostdatetime
                lostother
                registrationdate
                registrationstatus
                birthdate
                birthdateisapproximate
                sex
                lifestage
                abouttext
                findermessage
                createdAt
                imageurlone
                imageurltwo
                imagetitleone
                imagetitletwo
                origin {
                    createdAt
                    kiosk {
                        name
                        kiosklocation {
                            type
                            city
                        }
                    }
                }
            }
        }
    }
`;

export default function OwnersProfile({ userData, setUserData }) {
    const context = useContext(AuthContext);

    const onCompletedGetUser = (data) => {
        setUserData(data.user);
        //toast.success('Profile fetched successfully');
    };

    const onErrorGetUser = (error) => {
        console.log(error);
        console.log({ error });
        if (error.message === 'Unauthenticated Context') {
            context.logout();
            return;
        }
        if (error.message === 'GraphQL error: User not found') {
            toast.error(error.message);
        } else {
            toast.error('Error getting owners data');
        }
    };

    const [getUser /*, { returnData } */] = useLazyQuery(GETOWNER, {
        variables: { username: context.user.username },
        fetchPolicy: 'network-only',
        onCompleted: onCompletedGetUser,
        onError: onErrorGetUser,
    });

    useEffect(() => {
        getUser();
        //debugger;
    }, [getUser]);

    function ownerDetails() {
        return <OwnersCard user={userData} />;
    }

    function detailsMarkup() {
        return (
            <div className="overflow-hidden bg-white shadow sm:rounded-lg border-solid border-2 border-gray">
                <div className=" px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-1">{ownerDetails()}</dl>
                </div>
            </div>
        );
    }
    return (
        <div className="py-2 pl-8 pr-3">
            <Heading heading="Owner Profile" />
            {userData ? (
                <>
                    {detailsMarkup()}
                    {/* {userData.id === 1004 && <Payments />} */}
                </>
            ) : null}
        </div>
    );
}
