import { useQuery, useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Heading from '../components/Heading';
import toast from 'react-hot-toast';
import { GET_PET } from './GET_PET';
import PetProfileEdit from '../components/PetProfile/PetProfileEdit';
import * as petApi from '../utility/api/pets';

const UPDATE_PET = gql`
    mutation updatePet($input: PetInput!) {
        updatePet(input: $input) {
            id
            name
            pettype
            microchipnumber
            lost
            lostdescription
            lostlocation
            lostdatetime
            lostother
            birthdate
            birthdateisapproximate
            sex
            lifestage
            abouttext
            findermessage
            createdAt
            imageurlone
            imageurltwo
            registrationstatus
            origin {
                createdAt
                kiosk {
                    name
                    kiosklocation {
                        type
                        city
                    }
                }
            }
        }
    }
`;

export default function EditPetsProfile({ getMyPets }) {
    const context = useContext(AuthContext);

    const navigate = useNavigate();
    const { id } = useParams();

    const onErrorGetPet = (error) => {
        console.log({ error });
        if (error.message === 'GraphQL error: Pet not found') {
            toast.error(error.message);
        } else {
            toast.error('Error getting owners data');
        }
        if (error.message === 'Unauthenticated Context') {
            context.logout();
        }
    };

    // const { data } = useQuery(GET_PET, {
    //     variables: { id: Number(id) },
    //     onError: onErrorGetPet,
    // });

    const [pet, setPet] = useState();

    const fetchData = async () => {
        const res = await petApi.getPet(id);

        if (res.pets) {
            return setPet(res.pets[0]);
        }

        onErrorGetPet(res);
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const [updatePet] = useMutation(UPDATE_PET);

    const onCompletedUpdatePet = (data) => {
        toast.success('Profile updated successfully');
        navigate(-1);
    };

    const onErrorUpdatePet = (error) => {
        if (error.message === 'GraphQL error: User not found') {
            toast.error(error.message);
        } else {
            toast.error('Error updating pet data');
        }
        if (error.message === 'Unauthenticated Context') {
            context.logout();
        }
    };

    const onSubmitChanges = async (data) => {
        // new
        const res = await petApi.updatePet(data.id, {
            name: data.name,
            sex: data.sex,
            lifestage: data.lifestage,
            birthdate: data.birthdate,
            birthdateisapproximate: data.birthdateisapproximate,
            abouttext: data.abouttext,
            findermessage: data.findermessage,
            registrationstatus: data.registrationstatus || 'active',
        });

        if (res.pet) {
            setPet(res.pet);
            return onCompletedUpdatePet();
        }

        onErrorUpdatePet(res);

        // updatePet({
        //   variables: {
        //     input: {
        //       id: data.id,
        //       name: data.name,
        //       sex: data.sex,
        //       lifestage: data.lifestage,
        //       birthdate: data.birthdate,
        //       birthdateisapproximate: data.birthdateisapproximate,
        //       abouttext: data.abouttext,
        //       findermessage: data.findermessage,
        //       registrationstatus: data.registrationstatus || "active",
        //     },
        //   },
        //   onCompleted: onCompletedUpdatePet,
        //   onError: onErrorUpdatePet,
        // });
    };

    return (
        <div className="py-2 pl-8 pr-3">
            <Heading heading="Pet Profile" />

            {pet && <PetProfileEdit petdata={pet} onSubmitChanges={onSubmitChanges} />}
        </div>
    );
}
