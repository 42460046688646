import React from 'react';
import CommonFiltersID from '../../../components/Common/id';

const FeedFiltersID = ({ name, setDashboardFilter }) => {
    const applyFilters = (dataContainerFilter, filterName) => {
        const filters = {
            kioskID: dataContainerFilter.kioskID,
            filterBy: filterName,
        };
        submitChanges(filters, filterName);
    };

    const resetFilters = () => {
        const filters = {
            kioskID: '',
            filterBy: undefined,
        };
        submitChanges(filters, name, true);
    };

    const submitChanges = (filters, filterName, isReset) => {
        setDashboardFilter(filters, filterName, isReset);
    };

    return (
        <CommonFiltersID
            name={name}
            applyFilters={applyFilters}
            resetFilters={resetFilters}
        />
    );
};

export default FeedFiltersID;
