import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import UserForm from '../components/UserForm';
import Heading from '../components/Heading';
import toast from 'react-hot-toast';
import { get } from 'lodash';

const EDITOWNER = gql`
    query EditOwner {
        user {
            id
            firstname
            lastname
            imageid
            imageUrl
            about
            phonenumber
            phonetype
            phonenumber2
            phonetype2
            phonenumber3
            phonetype3
            email
            emailtype
            email2
            emailtype2
            email3
            emailtype3
            location {
                id
                type
                street1
                street2
                city
                zip
                state
            }
        }
    }
`;
const UPDATEUSER = gql`
    mutation UpdateUser($input: UserLocationInput!) {
        updateUser(input: $input) {
            firstname
            lastname
            about
            phonenumber
            phonetype
            phonenumber2
            phonetype2
            phonenumber3
            phonetype3
            email
            emailtype
            email2
            emailtype2
            email3
            emailtype3
            location {
                id
                type
                street1
                street2
                city
                zip
                state
            }
        }
    }
`;

export default function EditOwnersProfile({ getMyPets }) {
    const [serverError, setServerError] = useState('');

    const context = useContext(AuthContext);
    const [owner, setUser] = useState();
    const navigate = useNavigate();

    const onCompletedGetUser = (data) => {
        setUser(data);
    };

    const onErrorGetUser = (error) => {
        console.log(error);
        console.log({ error });
        console.log(error.message);
        //const message = get(error, 'message', '');
        if (error.message === 'GraphQL error: User not found') {
            toast.error(error.message);
        } else {
            toast.error('Error getting owners data');
        }
        if (error.message === 'Unauthenticated Context') {
            context.logout();
        }
    };

    const [getUser] = useLazyQuery(EDITOWNER, {
        variables: { username: context.user.username },
        fetchPolicy: 'network-only',
        onCompleted: onCompletedGetUser,
        onError: onErrorGetUser,
    });

    useEffect(() => {
        getUser();
        //debugger;
    }, [getUser]);

    const [updateUser /*, { mutationData, mutationLoading, mutationError } */] = useMutation(UPDATEUSER);

    const onCompletedUpdateUser = (data) => {
        toast.success('Profile updated successfully');
        getMyPets();
        navigate(-1);
    };

    const onErrorUpdateUser = (error) => {
        console.log({ error });
        const err = { error }.error;
        if (
            err &&
            err.graphQLErrors &&
            err.graphQLErrors[0] &&
            err.graphQLErrors[0].extensions &&
            err.graphQLErrors[0].extensions.exception &&
            err.graphQLErrors[0].extensions.exception.thrownValue &&
            err.graphQLErrors[0].extensions.exception.thrownValue.email
        ) {
            setServerError(err.graphQLErrors[0].extensions.exception.thrownValue.email);
            return;
        }
        if (error.message === 'Unauthenticated Context') {
            context.logout();
        }
        if (get(error, 'message', '')) {
            toast.error(error.message);
            return;
        } else {
            toast.error('Error updating owner data');
        }
    };

    const onSubmitChanges = async (data) => {
        const { __typename, id, ...location } = {
            ...data.location,
            type: '',
        };
        const { __typename: __ignore, ...input } = {
            ...data,
            location,
            phonetype: 'CELL',
        };

        updateUser({
            variables: { input },
            onCompleted: onCompletedUpdateUser,
            onError: onErrorUpdateUser,
        });
    };

    const detailsMarkup = () => {
        if (owner?.user) {
            return <UserForm userdata={owner.user} onSubmitChanges={onSubmitChanges} serverError={serverError} />;
        }
    };

    return (
        <>
            <div className="py-2 pl-8 pr-3">
                <Heading heading="Owner Profile" />
                {detailsMarkup()}
            </div>
        </>
    );
}
