import { gql, useLazyQuery } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import React, { createContext, useEffect, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const initialState = {
    user: null,
};

if (localStorage.getItem('accesstoken')) {
    const decodedToken = jwtDecode(localStorage.getItem('accesstoken'));

    if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem('accesstoken');
    } else {
        initialState.user = decodedToken;
    }
}
const AuthContext = createContext({
    user: null,
    login: (userData) => {}, //todo: implement, see below
    logout: () => {}, //todo: implement, see below
    refresh: () => {}, //todo: implement, see below
});

function authReducer(state, action) {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                user: { ...action.payload, is_admin: !!action.payload?.admin_id },
            };
        case 'LOGOUT':
            return {
                ...state,
                user: null,
            };
        default:
            return state;
    }
}

function AuthProvider(props) {
    const [state, dispatch] = useReducer(authReducer, initialState);

    const navigate = useNavigate();
    const location = useLocation();

    const REFRESH = gql`
        query refresh($username: String!) {
            refresh(username: $username) {
                accesstoken
                username
            }
        }
    `;

    //this will be called after we get a response from the server
    const login = (userData) => {
        localStorage.setItem('accesstoken', userData.accesstoken);
        localStorage.setItem('isAdmin', !!userData.admin_id);
        dispatch({
            type: 'LOGIN',
            payload: userData,
        });
    };
    const logout = () => {
        localStorage.clear();
        dispatch({ type: 'LOGOUT' });
    };
    const onCompletedRefresh = (data) => {
        console.log('onCompletedRefresh');
        console.log(data);
        if (data && data.refresh && data.refresh.accesstoken) {
            login(data.refresh);
            return data.refresh.accesstoken;
        }
    };

    const onErrorRefresh = (error) => {
        console.log('onErrorRefresh');
        //toast.error('Error refreshing your session: '+ error.message);
        if (error.message === 'Unauthenticated Context') {
            logout();
        }
        logout();
    };
    const [refreshAccess] = useLazyQuery(REFRESH, {
        fetchPolicy: 'network-only',
        onCompleted: onCompletedRefresh,
        onError: onErrorRefresh,
    });
    const refresh = () => {
        console.log('refreshing');
        refreshAccess({ variables: { username: state.user.username } });
    };

    useEffect(() => {
        if (
            !localStorage.getItem('accesstoken') &&
            !['/register', '/forgotusername', '/forgotpassword', '/resetpassword', '/admin', undefined].includes(
                location?.pathname,
            )
        ) {
            navigate('/login', {
                state: {
                    from: location.pathname,
                },
            });
        }
    }, [localStorage.getItem('accesstoken')]);

    return (
        <AuthContext.Provider
            value={{ user: { ...state.user, is_admin: !!state.user?.is_admin }, login, logout, refresh }}
            {...props}
        /> //expand in the future by adding more methods
    );
}

export { AuthContext, AuthProvider };
