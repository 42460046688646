import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, UserIcon, HeartIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import CatIcon from '../components/CatIcon';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
function Sidebar({ sidebarOpen, setSidebarOpen, pets, overviewData }) {
    const location = useLocation();
    const [navigation, setNavigation] = useState({
        pet: {
            id: 1,
            name: 'Pet Profiles',
            icon: HeartIcon,
            current: location.pathname.includes('/ctc/petprofiles/7'),
            children: [{ name: 'Fido', icon: CatIcon, href: '/ctc/petprofiles/7', id: 7 }],
        },
        profile: {
            id: 2,
            name: 'Owner Profile',
            href: '/ctc/ownersprofile',
            icon: UserIcon,
            current: location.pathname.includes('/ctc/ownersprofile'),
        },
    });

    useEffect(() => {
        console.log(overviewData);
        if (overviewData?.is_admin) {
            setNavigation({
                ...navigation,
                dashboard: {
                    id: 3,
                    name: 'Dashboard',
                    href: '/ctc/dashboard',
                    icon: UserIcon,
                    current: location.pathname.includes('/ctc/dashboard'),
                },
            });
        }
    }, [overviewData]);

    function getProfilePic(pet) {
        if (pet.imageurlone) {
            return (
                <div className="sm:col-span-1 sm:row-span-1">
                    <div className="mx-auto">
                        <img className="mx-auto h-12 w-12 object-cover rounded-full" src={pet.imageurlone} alt="" />
                    </div>
                </div>
            );
        } else if (pet.pettype === 'DOG') {
            return (
                <div className="sm:col-span-1 sm:row-span-1">
                    <div className="mx-auto">
                        <img
                            className="mx-auto h-12 w-12  object-cover rounded-full"
                            src="/dog-placeholder.png"
                            alt=""
                        />
                    </div>
                </div>
            );
        } else if (pet.pettype === 'CAT') {
            return (
                <div className="sm:col-span-1 sm:row-span-1">
                    <div className="mx-auto">
                        <img
                            className="mx-auto h-12 w-12 object-cover rounded-full"
                            src="/cat-placeholder.png"
                            alt=""
                        />
                    </div>
                </div>
            );
        }
    }

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-ctclightblue-600 pt-5 pb-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Link className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </Link>
                                </Transition.Child>
                                {/* <div className="flex flex-shrink-0 items-center justify-center px-1">
                        <img
                            className="h-16 w-auto"
                            src="/CTC-Logo-2.png"
                            alt="Your Company"
                        />
                    </div> */}
                                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                                    <nav className="space-y-1 px-2">
                                        {Object.values(navigation).map((item) => {
                                            if (item.id === 1) {
                                                //pet
                                                return (
                                                    <Fragment key={'' + item.id}>
                                                        <div
                                                            to={item.href}
                                                            className="font-display text-ctcblack-100 group flex items-center px-2 py-2 text-sm font-semibold rounded-md"
                                                        >
                                                            {/* { petExpanded ? (
                                            <ChevronDownIcon className="mr-3 h-6 w-6 flex-shrink-0 text-ctcblack-300" aria-hidden="true" />
                                        ) : (
                                            <ChevronRightIcon className="mr-3 h-6 w-6 flex-shrink-0 text-ctcblack-300" aria-hidden="true" />
                                        )} */}
                                                            <div
                                                                className="flex invisible mr-2 h-6 w-6 flex-shrink-0 text-ctcblack-300"
                                                                aria-hidden="true"
                                                            ></div>
                                                            {item.name}
                                                        </div>
                                                        {pets && pets.length > 0
                                                            ? pets.map((pet) => {
                                                                  return (
                                                                      <Link
                                                                          title={pet.name}
                                                                          key={'' + item.id + pet.id}
                                                                          to={`/ctc/petprofiles/${pet.id}`}
                                                                          className={classNames(
                                                                              location.pathname.includes(
                                                                                  `/ctc/petprofiles/${pet.id}`,
                                                                              )
                                                                                  ? 'bg-ctclightblue-800 text-ctcdarkblue font-semibold'
                                                                                  : 'text-ctcblack-100 hover:bg-ctclightblue-600',
                                                                              'font-display group flex items-center px-2 pt-3 pb-4 text-sm font-medium rounded-md',
                                                                          )}
                                                                      >
                                                                          <div
                                                                              className="flex invisible mr-2 h-6 w-6 flex-shrink-0 text-ctcblack-300"
                                                                              aria-hidden="true"
                                                                          ></div>
                                                                          <div
                                                                              className="flex items-center justify-center mr-2 h-6 flex-shrink-0 text-ctcblack-300"
                                                                              aria-hidden="true"
                                                                          >
                                                                              {getProfilePic(pet)}
                                                                          </div>
                                                                          <span className="text-ellipsis max-w-[140px] inline-block overflow-hidden ">
                                                                              {pet.name}
                                                                          </span>
                                                                          {/* {pet.reward ? (
                                                    <div className="text-ctcyellow-600 ml-2 mr-1">
                                                        <RewardIcon className="mr-1 h-6 w-6 flex-shrink-0"></RewardIcon>
                                                    </div>) : null} */}
                                                                      </Link>
                                                                  );
                                                              })
                                                            : null}
                                                    </Fragment>
                                                );
                                            } else if (!item.locked)
                                                return (
                                                    <Link
                                                        key={'' + item.id}
                                                        to={item.href}
                                                        className={classNames(
                                                            location.pathname.includes(item.href)
                                                                ? 'bg-ctclightblue-800 text-ctcdarkblue font-semibold'
                                                                : 'text-ctcblack-100 hover:bg-ctclightblue-700',
                                                            'ml-8 font-display group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                                                        )}
                                                    >
                                                        <item.icon
                                                            className="hidden mr-3 h-6 w-6 flex-shrink-0 text-ctcblack-300"
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                        {/* {(item.reward && pets && pets[0] && pets[0].userReward) ? (
                                        <div className="text-ctcyellow-600 ml-2 mr-1">
                                            <RewardIcon className="mr-1 h-6 w-6 flex-shrink-0"></RewardIcon>
                                        </div>) : null} */}
                                                    </Link>
                                                );
                                            else
                                                return (
                                                    <div
                                                        key={'' + item.id}
                                                        to={item.href}
                                                        className=" text-ctcblack-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                                    >
                                                        <LockClosedIcon
                                                            className="mr-3 h-6 w-6 flex-shrink-0 text-ctcblack-300"
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </div>
                                                );
                                        })}
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop  style={{marginTop: '50px'}} */}
            <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
                <div
                    className="flex flex-shrink-0 items-center justify-center px-1 mt-2.5 ml-2.5"
                    style={{ marginTop: '10px 0 0 10px' }}
                >
                    <img className="w-[30vh]" src="/logo-wide.jpg" alt="Your Company" />
                </div>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-grow flex-col overflow-y-auto bg-ctclightblue-600 mt-2.5">
                    <div className="mt-5 flex flex-1 flex-col">
                        <nav className="flex-1 space-y-1 px-2 pb-4">
                            {Object.values(navigation).map((item) => {
                                if (item.id === 1) {
                                    //pet
                                    return (
                                        <Fragment key={'' + item.id}>
                                            <div
                                                to={item.href}
                                                className="font-display text-ctcblack-100 group flex items-center px-2 py-2 text-sm font-semibold rounded-md"
                                            >
                                                {/* { petExpanded ? (
                                <ChevronDownIcon className="mr-3 h-6 w-6 flex-shrink-0 text-ctcblack-300" aria-hidden="true" />
                            ) : (
                                <ChevronRightIcon className="mr-3 h-6 w-4 flex-shrink-0 text-ctcblack-300" aria-hidden="true" />
                            )} */}
                                                <div
                                                    className="flex invisible mr-2 h-6 w-6 flex-shrink-0 text-ctcblack-300"
                                                    aria-hidden="true"
                                                ></div>
                                                {item.name}
                                            </div>
                                            {pets && pets.length > 0
                                                ? pets.map((pet) => {
                                                      return (
                                                          <Link
                                                              title={pet.name}
                                                              key={'' + item.id + pet.id}
                                                              to={`/ctc/petprofiles/${pet.id}`}
                                                              className={classNames(
                                                                  location.pathname.includes(
                                                                      `/ctc/petprofiles/${pet.id}`,
                                                                  )
                                                                      ? 'bg-ctclightblue-800 text-ctcdarkblue font-semibold'
                                                                      : 'text-ctcblack-100 hover:bg-ctclightblue-700',
                                                                  'font-display group flex items-center px-2 pt-3 pb-4 text-sm font-medium rounded-md ',
                                                              )}
                                                          >
                                                              <div
                                                                  className="flex invisible mr-2 h-6 w-6 flex-shrink-0 text-ctcblack-300"
                                                                  aria-hidden="true"
                                                              ></div>
                                                              <div
                                                                  className="flex items-center justify-center mr-2 h-6 flex-shrink-0 text-ctcblack-300"
                                                                  aria-hidden="true"
                                                              >
                                                                  {getProfilePic(pet)}
                                                              </div>
                                                              <span className="text-ellipsis max-w-[140px] inline-block overflow-hidden">
                                                                  {pet.name}
                                                              </span>
                                                              {/* {pet.reward ? (
                                            <div className="text-ctcyellow-600 ml-2 mr-1">
                                                <RewardIcon className="mr-1 h-6 w-6 flex-shrink-0"></RewardIcon>
                                            </div>) : null} */}
                                                          </Link>
                                                      );
                                                  })
                                                : null}
                                        </Fragment>
                                    );
                                } else if (!item.locked)
                                    return (
                                        <Fragment key={'' + item.id}>
                                            <div className="w-[90%] mx-auto border-b-2 border-sky-200"></div>
                                            <Link
                                                to={item.href}
                                                className={classNames(
                                                    location.pathname.includes(item.href)
                                                        ? 'bg-ctclightblue-800 text-ctcdarkblue font-semibold'
                                                        : 'text-ctcblack-100 hover:bg-ctclightblue-700',
                                                    'ml-8 mt-4 font-display group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                                                )}
                                            >
                                                <item.icon
                                                    className="hidden mr-3 h-6 w-6 flex-shrink-0 text-ctcblack-300"
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                                {/* {(item.reward && pets && pets[0] && pets[0].userReward) ? (
                                        <div className="text-ctcyellow-600 ml-2 mr-1">
                                            <RewardIcon className="mr-1 h-6 w-6 flex-shrink-0"></RewardIcon>
                                        </div>) : null} */}
                                            </Link>
                                        </Fragment>
                                    );
                                else
                                    return (
                                        <div
                                            key={'' + item.id}
                                            to={item.href}
                                            className=" text-ctcblack-500 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                            <LockClosedIcon
                                                className="mr-3 h-6 w-6 flex-shrink-0 text-ctcblack-300"
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </div>
                                    );
                            })}
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidebar;
