import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation, Routes, Route, Navigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AuthContext } from '../context/AuthContext';
import Toolbar from '../components/Toolbar';
import Sidebar from '../components/Sidebar';
import OwnersProfile from './OwnersProfile';
import PetProfile from './PetProfile';
import EditOwnersProfile from './EditOwnersProfile';
import EditPetsProfile from './EditPetsProfile';
import { useLazyQuery, gql, useQuery } from '@apollo/client';
import LostPetForm from '../components/LostPetForm';
import Dashboard from './Dashboard';
import ErrorPage from './ErrorPage';
import * as petApi from '../utility/api/pets';
import { PaymentMethodsPage } from './PaymentMethods/PaymentMethodsPage';
import { PaymentsPage } from './PaymentsPage';

const OVERVIEWGETMYPETS = gql`
    query OverviewGetMyPets($username: String!) {
        user(username: $username) {
            id
            firstname
            lastname
            imageid
            imageUrl
            phonenumber
            phonenumber2
            phonenumber3
            phonetype
            phonetype2
            phonetype3
            email
            email2
            email3
            emailtype
            emailtype2
            emailtype3
            about
            location {
                id
                street1
                street2
                city
                state
                zip
            }
            pets {
                id
                name
                pettype
                microchipnumber
                lost
                lostdescription
                lostlocation
                lostdatetime
                lostother
                registrationdate
                registrationstatus
                birthdate
                birthdateisapproximate
                sex
                lifestage
                abouttext
                findermessage
                createdAt
                imageurlone
                imageurltwo
                imagetitleone
                imagetitletwo
                origin {
                    createdAt
                    kiosk {
                        name
                        kiosklocation {
                            type
                            city
                        }
                    }
                }
            }
        }
    }
`;

function Overview() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [userData, setUserData] = useState();
    const [petExpanded, setPetExpanded] = useState(false);

    const context = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const isAdmin = JSON.parse(localStorage.getItem('isAdmin'));

    useEffect(() => {
        if (isAdmin) {
            navigate('/dashboard');
        }
    }, [isAdmin, navigate]);

    const onCompletedGetMyPetsRefresh = (data) => {
        // setMyPetData(data.User.pets);
    };
    const onErrorGetMyPets = (error) => {
        console.log(error);
        toast.error('Error getting data for pet menu');
        if (error.message === 'Unauthenticated Context') {
            context.logout();
        }
    };

    // const { loading, data } = useQuery(OVERVIEWGETMYPETS, {
    //     variables: {
    //         username: context.user.username,
    //     },
    //     onError: onErrorGetMyPets,
    // });

    const [myPetData, setMyPetData] = useState();
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        const res = await petApi.getPets();

        if (res.pets) {
            return setMyPetData(res.pets);
        }

        onErrorGetMyPets(res);
    };

    useEffect(() => {
        setLoading(true);
        fetchData().finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (location.pathname === '/ctc/petprofiles' && myPetData && myPetData[0]) {
            navigate(`/ctc/petprofiles/${myPetData[0].id}`);
        }
    }, [location.pathname, myPetData, navigate]);

    const getMyPetsRefresh = async () => {
        setLoading(true);
        fetchData().finally(() => setLoading(false));
    };
    // const [getMyPetsRefresh] = useLazyQuery(OVERVIEWGETMYPETS, {
    //     variables: {
    //         username: context.user.username,
    //     },
    //     fetchPolicy: 'network-only',
    //     onCompleted: onCompletedGetMyPetsRefresh,
    //     onError: onErrorGetMyPets,
    // });

    if (loading) return <div>Loading...</div>;

    const ProtectedRoute = ({ element }) => {
        return context.user.is_admin || isAdmin ? element : <ErrorPage />;
    };

    const ViewerRoute = ({ element }) => {
        return !context.user.is_admin ? element : <ErrorPage />;
    };

    return (
        <>
            <div className="flex">
                {!context.user.is_admin && !isAdmin ? (
                    <Sidebar
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        petExpanded={petExpanded}
                        setPetExpanded={setPetExpanded}
                        pets={myPetData}
                        overviewData={userData}
                        isAdmin={false}
                    />
                ) : null}

                <div className={`flex flex-1 flex-col ${isAdmin ? '' : 'md:pl-64'}`}>
                    <Toolbar setSidebarOpen={setSidebarOpen} />
                    <Routes>
                        <Route path="/" element={<ViewerRoute element={<PetProfile />} />} />
                        <Route path="/lostpetform" element={<ViewerRoute element={<LostPetForm />} />} />
                        <Route
                            path="/ownersprofile"
                            element={<OwnersProfile userData={userData} setUserData={setUserData} />}
                        />
                        <Route
                            path="/ownersprofile/edit"
                            element={<ViewerRoute element={<EditOwnersProfile getMyPets={getMyPetsRefresh} />} />}
                        />
                        <Route path="/petprofiles" element={<ViewerRoute element={<PetProfile />} />} />
                        <Route path="/payments/*" element={<ViewerRoute element={<PaymentsPage />} />} />
                        <Route path="/petprofiles/:id" element={<ViewerRoute element={<PetProfile />} />} />
                        <Route
                            path="/petprofiles/:id/edit"
                            element={<ViewerRoute element={<EditPetsProfile getMyPets={getMyPetsRefresh} />} />}
                        />
                        <Route
                            path="/dashboard"
                            element={
                                <ProtectedRoute element={<Dashboard userData={userData} setUserData={setUserData} />} />
                            }
                        />
                    </Routes>
                </div>
            </div>
        </>
    );
}

export default Overview;
