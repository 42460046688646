import { useState, useEffect, useRef, useContext } from 'react';
import toast from 'react-hot-toast';
import {
    createPaymentMethod,
    createTransaction,
    deletePaymentMethod,
    getPaymentMethods,
    updatePaymentMethod,
} from './api/payments';
import { AuthContext } from '../context/AuthContext';

export const useForm = (callback, initialState = {}) => {
    const [values, setValues] = useState(initialState);

    const onChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        callback();
    };

    return {
        onChange,
        onSubmit,
        values,
    };
};

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

export const useIsFirstRender = () => {
    const is_first_render = useRef(true);

    useEffect(() => {
        is_first_render.current = false;
    }, []);

    return is_first_render.current;
};

export const useCreditCards = () => {
    const [creditCards, setCreditCards] = useState([]);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchCards = async () => {
        setLoading(true);
        const res = await getPaymentMethods();
        if (res.paymentsMethods) {
            setCreditCards(res.paymentsMethods);
            setLoading(false);
            return;
        }
        toast.error(res);
        setLoading(false);
    };

    const deleteCard = async (id) => {
        setLoading(true);
        const res = await deletePaymentMethod(id);
        if (res.paymentsMethods) {
            setCreditCards(res.paymentsMethods);
            setLoading(false);
            toast.success('Payment method deleted successfully');
            return;
        }
        toast.error(res);
        setLoading(false);
    };

    const updateCard = async (id) => {
        setLoading(true);
        const res = await updatePaymentMethod(id);
        if (res.paymentsMethods) {
            setCreditCards(res.paymentsMethods);
            setLoading(false);
            return;
        }
        toast.error(res);
        setLoading(false);
    };

    const payWithMethodId = async (paymentMethodId, petId) => {
        setLoading(true);

        const res = await createTransaction(paymentMethodId, petId);
        if (res?.pets) {
            setLoading(false);
            toast.success('Payment successful');
            return;
        }

        toast.error(res);
        setLoading(false);
    };

    const createAndPay = async (paymentMethodId, petId) => {
        setLoading(true);
        const res = await createPaymentMethod(paymentMethodId);
        if (res?.paymentsMethods) {
            const response = await createTransaction(res?.paymentsMethods[res?.paymentsMethods.length - 1]?.id, petId);
            if (response?.pets) {
                setLoading(false);
                setSuccess(true);
                toast.success('Payment successful');
                setCreditCards(res?.paymentsMethods);
                return;
            }
            toast.error(response);
            setLoading(false);
            return;
        }
        toast.error(res);
        setLoading(false);
    };

    const createCard = async (paymentMethodId) => {
        setLoading(true);
        const res = await createPaymentMethod(paymentMethodId);
        if (res?.paymentsMethods) {
            setLoading(false);
            setSuccess(true);
            setCreditCards(res.paymentsMethods);
            return;
        }
        toast.error(res);
        setLoading(false);
    };

    return {
        loading,
        creditCards,
        setCreditCards,
        fetchCards,
        deleteCard,
        updateCard,
        createCard,
        success,
        setSuccess,
        errorMessage,
        setErrorMessage,
        setLoading,
        payWithMethodId,
        createAndPay,
    };
};
