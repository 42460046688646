export default function Heading({ heading }) {
    return (
        <div className="md:flex md:items-center md:justify-between mt-4 mb-4">
            <div className="min-w-0 flex-1">
                <h2 className="font-display text-2xl leading-7 text-ctcblack-500 sm:truncate sm:text-3xl sm:tracking-tight">
                    {heading}
                </h2>
            </div>
        </div>
    );
}
