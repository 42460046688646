import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { Loader } from '../../components/Loader';
import './Payment.css';
import { environment as environmentLocal } from '../../config/config';
import { environment as environmentCi } from '../../config/environment.ci';

const environment = window.location.hostname !== 'localhost' ? environmentCi : environmentLocal;

const stripePromise = loadStripe(environment.STRIPE_PUBLIC_KEY);

const CheckoutForm = ({
    close,
    loading,
    submit,
    setLoading,
    errorMessage,
    setErrorMessage,
    success,
    text,
    type,
    petId,
    callback,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (!stripe || !elements) {
            setLoading(false);
            return;
        }

        const { error: submitError } = await elements.submit();
        if (submitError) {
            setErrorMessage(submitError.message);
            setLoading(false);
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            elements,
        });

        if (error) {
            setErrorMessage(error.message);
            setLoading(false);
            return;
        }
        if (paymentMethod) {
            if (type === 'pay') {
                await submit(paymentMethod.id, petId);
                await callback();
                return;
            }
            await submit(paymentMethod.id);
        }
    };

    if (!stripe || !elements) {
        return <Loader />;
    }

    if (success) {
        if (type !== 'add') {
            close();
            return;
        }

        setTimeout(() => {
            close();
        }, 2000);
        return (
            <div className="flex flex-col justify-center align-center gap-4">
                <img src="/logo-wide.png" />
                <h2 className="text-lg font-medium">Card added successfully</h2>
            </div>
        );
    }

    return (
        <form className="stripe-card" onSubmit={handleSubmit}>
            <PaymentElement />
            {errorMessage && <div className="text-red-600 text-sm">{errorMessage}</div>}
            <button
                type="submit"
                disabled={!stripe || !elements}
                className="w-full inline-flex justify-center items-center rounded-md border border-transparent bg-indigo-600 py-2 px-8 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-nonehover:disabled:bg-gray-300 hover:enabled:bg-indigo-700"
            >
                {!loading ? text : <Loader />}
            </button>
        </form>
    );
};

export const StripeCard = ({
    close,
    loading,
    submit,
    setLoading,
    errorMessage,
    setErrorMessage,
    success,
    text = 'Add Payment Method',
    type = 'add',
    petId = '',
    callback = null,
}) => {
    return (
        <Elements
            stripe={stripePromise}
            options={{
                paymentMethodCreation: 'manual',
                mode: 'setup',
                currency: 'usd',
                appearance: {
                    theme: 'stripe',
                    labels: 'floating',
                    variables: {
                        fontFamily: '"Fredoka", system-ui, sans-serif',
                        colorPrimary: '#333',
                        colorText: '#333',
                    },
                    rules: {
                        '.Input, .Block': {
                            fontFamily: '"Fredoka", system-ui, sans-serif',
                            colorPrimary: '#333',
                            colorText: '#333',
                        },
                    },
                },
                locale: 'en',
            }}
        >
            <CheckoutForm
                close={close}
                loading={loading}
                submit={submit}
                setLoading={setLoading}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                success={success}
                text={text}
                type={type}
                petId={petId}
                callback={callback}
            />
        </Elements>
    );
};
