export const ScanReportTableHeaders = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
    },
    {
        title: 'Microchip Number',
        dataIndex: 'microchipnumber',
        key: 'microchipnumber',
        sorter: true,
    },
    {
        title: 'Kiosk Name',
        dataIndex: 'kioskname',
        key: 'kioskname',
        sorter: true,
    },
    {
        title: 'Kiosk ID',
        dataIndex: 'kioskid',
        key: 'kioskid',
        sorter: true,
    },
    {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
    },
    {
        title: 'Kiosk Use Type',
        dataIndex: 'kioskusetype',
        key: 'kioskusetype',
        sorter: true,
    },
];


export const UserTableHeaders = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
    },
    {
        title: 'First Name',
        dataIndex: 'firstname',
        key: 'firstname',
        sorter: true,
    },
    {
        title: 'Last Name',
        dataIndex: 'lastname',
        key: 'lastname',
        sorter: true,
    },
    {
        title: 'Phone Number',
        dataIndex: 'phonenumber',
        key: 'phonenumber',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        sorter: true,
    },
    {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
    },
];
