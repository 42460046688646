import React from 'react';
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import {Tooltip} from "react-tooltip";

const ChipInfo = ({ petdata }) => {
    const prefix = (label, value) => (value ? `${label} ${value}` : '');

    return (
        <>
            <dt className="font-display flex text-sm font-medium text-black mt-6">
                <span className="flex mr-2">Chip Info</span>
                <InformationCircleIcon
                    data-tooltip-id="chip-info"
                    data-tooltip-content="Microchip data is non-editable for security and accuracy, ensuring reliable identification for lost pet reunions, if incorrect contact us at support@checkthechip.com"
                    className="-mr-0.5 h-5 w-5"
                    aria-hidden="true"
                />
                <Tooltip
                    id="chip-info"
                    place="right"
                    border="1px solid #36A5FF"
                    style={{
                        backgroundColor: "rgb(255, 255, 255)",
                        width: "50%",
                        color: "#3092e3",
                    }}
                />
            </dt>
            <dd className="font-display mt-1 text-sm text-gray-900">
                Registered
                {prefix(" at", petdata?.origin?.kiosk?.kiosklocation?.city)}
                {prefix(" on", petdata?.origin?.createdAt)}
            </dd>
            <dd className="font-display mt-1 text-sm text-gray-900">
                Microchip Number: {petdata.microchipnumber}
            </dd>
        </>
    );
};

export default ChipInfo;
