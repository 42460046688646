function CatIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="mr-1 mt-2 w-8 h-6"
      viewBox="0 0 400 400"
    >
      <path
        id="Selection"
        stroke="currentColor"
        d="M 50.00,274.00
           C 41.52,276.06 31.39,274.30 32.85,263.00
             33.76,255.96 39.80,252.87 40.81,248.00
             41.24,245.93 39.62,235.84 39.14,233.00
             39.14,233.00 39.14,213.00 39.14,213.00
             39.01,204.32 40.87,191.50 42.88,183.00
             43.66,179.74 46.72,169.56 46.40,167.00
             46.08,164.41 42.60,158.75 41.26,156.00
             41.26,156.00 33.42,137.00 33.42,137.00
             26.48,118.60 21.93,97.54 19.84,78.00
             19.84,78.00 18.83,66.00 18.83,66.00
             18.21,59.45 16.33,51.55 21.28,46.13
             27.66,39.13 35.62,42.89 43.00,45.26
             43.00,45.26 53.00,48.33 53.00,48.33
             71.00,54.33 89.45,62.62 106.00,71.86
             113.01,75.77 128.70,86.81 135.00,88.20
             140.10,89.33 154.07,85.73 160.00,84.75
             173.32,82.57 186.52,81.98 200.00,82.00
             209.06,82.01 229.04,84.46 238.00,86.42
             242.66,87.45 255.19,91.29 259.00,90.32
             266.06,88.52 282.21,74.30 289.00,69.43
             304.67,58.18 321.87,48.70 340.00,42.05
             348.96,38.77 366.52,30.63 372.70,42.05
             374.85,46.02 374.73,50.65 375.17,55.00
             375.17,55.00 376.00,83.00 376.00,83.00
             375.75,104.34 369.02,127.84 359.74,147.00
             359.74,147.00 348.95,166.00 348.95,166.00
             347.62,168.08 343.49,173.42 343.20,175.42
             342.95,177.16 344.82,182.06 345.41,184.00
             347.59,191.13 348.44,193.44 349.75,201.00
             352.08,214.42 352.15,220.56 352.00,234.00
             352.00,234.00 351.33,246.00 351.33,246.00
             352.83,252.65 362.46,255.76 362.40,265.00
             362.32,275.65 352.05,277.89 344.00,273.00
             346.11,282.21 358.21,286.73 361.30,296.00
             364.61,305.93 355.04,312.71 346.00,309.26
             341.95,307.72 335.64,300.32 331.00,297.00
             328.91,303.55 323.46,309.81 319.00,315.00
             307.08,328.88 295.04,338.55 279.00,347.30
             226.27,376.09 159.14,373.45 108.00,342.60
             94.83,334.66 84.04,325.65 74.00,314.00
             69.74,309.06 65.36,304.16 63.00,298.00
             58.66,301.11 52.86,307.79 49.00,309.26
             39.96,312.71 30.39,305.93 33.70,296.00
             34.96,292.22 41.15,285.02 44.09,282.17
             47.42,278.94 49.40,278.85 50.00,274.00 Z
           M 354.00,63.00
           C 338.25,65.47 308.67,83.96 296.00,94.00
             285.42,102.39 271.35,114.74 257.00,113.91
             257.00,113.91 236.00,109.45 236.00,109.45
             226.20,107.48 216.92,106.59 207.00,105.91
             207.00,105.91 197.00,105.00 197.00,105.00
             183.63,104.85 170.17,106.86 157.00,109.08
             146.27,110.89 141.00,113.35 130.00,110.52
             119.31,107.77 112.29,101.48 103.00,96.01
             89.62,88.14 56.59,71.25 42.00,69.00
             44.87,90.76 48.64,112.37 56.42,133.00
             56.42,133.00 63.04,148.00 63.04,148.00
             66.87,154.88 71.47,159.66 70.90,168.00
             70.90,168.00 65.21,191.00 65.21,191.00
             62.17,205.34 61.08,219.45 63.00,234.00
             91.33,219.03 106.84,214.63 139.00,215.00
             145.93,215.09 155.26,216.77 156.71,225.00
             157.23,227.93 156.37,230.64 154.57,232.96
             148.33,241.01 139.47,236.68 131.00,237.04
             115.76,237.68 100.00,240.66 86.00,246.86
             81.79,248.73 69.83,254.42 68.93,259.04
             68.57,260.88 69.38,262.37 70.00,264.00
             70.00,264.00 95.00,254.72 95.00,254.72
             106.76,251.22 118.83,250.05 131.00,249.18
             134.42,248.94 135.84,249.53 139.00,249.83
             146.13,250.52 155.33,250.54 156.76,260.00
             157.65,265.88 152.79,271.66 147.00,272.22
             147.00,272.22 129.00,272.22 129.00,272.22
             110.04,272.03 97.13,277.15 80.00,284.00
             87.68,299.67 105.20,314.86 120.00,323.80
             161.45,348.83 213.60,352.03 258.00,333.00
             275.18,325.64 286.06,317.49 298.96,304.00
             303.98,298.75 310.80,290.91 313.00,284.00
             296.96,274.91 277.20,271.97 259.00,272.00
             254.13,272.01 248.14,273.47 244.02,270.99
             236.72,266.59 236.21,257.76 243.10,252.70
             246.95,249.87 251.48,250.01 256.00,250.00
             272.54,249.97 281.54,249.77 298.00,254.15
             298.00,254.15 324.00,263.00 324.00,263.00
             324.00,263.00 326.00,256.00 326.00,256.00
             326.00,256.00 310.00,246.86 310.00,246.86
             291.02,238.45 277.38,236.97 257.00,237.00
             252.45,237.01 246.98,238.03 243.10,235.27
             234.85,229.41 237.95,218.53 247.00,215.65
             254.52,213.25 266.21,214.05 274.00,214.83
             287.68,216.20 298.01,218.29 311.00,223.05
             311.00,223.05 329.00,231.00 329.00,231.00
             329.00,219.19 328.35,207.41 324.96,196.00
             322.78,188.62 316.77,177.04 318.14,170.00
             319.11,165.01 323.63,160.23 326.48,156.00
             331.01,149.29 335.21,142.29 338.74,135.00
             349.87,112.02 354.00,88.33 354.00,63.00 Z
           M 190.00,222.52
           C 218.82,217.95 236.06,254.44 222.44,271.96
             215.27,281.19 203.73,282.12 193.00,282.00
             188.49,281.94 184.16,281.32 180.00,279.47
             154.95,268.32 166.67,228.94 190.00,222.52 Z
           M 195.09,244.74
           C 191.75,246.52 186.38,253.42 189.06,257.44
             190.57,259.70 195.54,260.03 198.00,259.81
             211.04,258.65 202.77,245.04 195.09,244.74 Z
           M 255.00,273.57
           C 263.44,272.25 270.81,278.27 268.43,287.00
             267.31,291.08 261.13,297.81 257.96,300.71
             240.93,316.32 218.13,325.26 195.00,325.00
             173.34,324.75 151.13,316.30 135.00,301.83
             131.69,298.86 126.22,293.19 124.72,289.00
             121.71,280.57 126.95,273.23 136.00,274.11
             142.55,274.75 147.23,281.62 152.00,285.70
             158.44,291.21 167.91,296.40 176.00,298.97
             195.78,305.26 216.81,302.09 234.00,290.48
             241.48,285.43 247.73,276.41 255.00,273.57 Z"
      />
    </svg>
  );
}

export default CatIcon;
