export const PetAvatar = ({ pet }) => {
    if (pet?.imageurlone) {
        return <img className="h-12 w-12 object-cover rounded-full" src={pet.imageurlone} alt="" />;
    }
    return (
        <img
            className="h-12 w-12  object-cover rounded-full"
            src={pet?.pettype === 'DOG' ? '/dog-placeholder.png' : '/cat-placeholder.png'}
            alt=""
        />
    );
};
