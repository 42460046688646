import axios from 'axios';
import toast from 'react-hot-toast';
import { environment as environmentCi } from '../config/environment.ci';
import { environment as environmentLocal } from '../config/config';
import { getApiService } from '../utility/api';

const environment = window.location.hostname !== 'localhost' ? environmentCi : environmentLocal;

export const uploadPhoto = async (formData, setFileUploaded, setImageSubmitted, setLoading, file, context, setOpen) => {
    setLoading(true);
    const apiUrl = environment.apiUrl + environment.ApiPostfix + '/';

    try {
        const response = await axios.post(new URL('images', apiUrl).href, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('accesstoken')}`,
            },
        });

        const link = response?.data?.link;
        const id = response?.data?.id;
        if (link) {
            await axios.put(link, file);
        }

        toast.success('Image uploaded successfully');
        setOpen && setOpen(false);
        setFileUploaded && setFileUploaded(true);
        setImageSubmitted && setImageSubmitted(true);
    } catch (error) {
        const err = { error };
        if (err.message) {
            toast.error(error.message);
        } else {
            toast.error('Error uploading image');
        }
        if (err && err.response && err.response.status === 403) {
            context.refresh();
        }
    } finally {
        setLoading(false);
    }
};
