import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { EnvelopeIcon } from '@heroicons/react/20/solid';
import toast from 'react-hot-toast';
import { gql, useLazyQuery } from '@apollo/client';
import { AuthContext } from '../context/AuthContext';
import { CtcLogo } from '../components/CtcLogo';

const FORGOT_PASSWORD = gql`
    query forgotPassword($email: String) {
        forgotPassword(email: $email)
    }
`;

function ForgotPassword() {
    const [email, setEmail] = useState();
    const [credId, setCredId] = useState('');
    const context = useContext(AuthContext);

    const onCompletedSendEmail = (data) => {
        //    console.log("HERE WE ARE: ", data.forgotPassword.id);
        setCredId(data);
    };

    const onErrorSendEmail = (error) => {
        if (error.message === 'User not found') {
            toast.error(error.message);
        } else {
            toast.error('Error getting data');
        }
        if (error.message === 'Unauthenticated Context') {
            context.logout();
        }
    };

    const [sendPasswordEmail] = useLazyQuery(FORGOT_PASSWORD, {
        variables: { email: String(email) },
        fetchPolicy: 'network-only',
        onCompleted: onCompletedSendEmail,
        onError: onErrorSendEmail,
    });

    const submit = (e) => {
        e.preventDefault();
        setEmail(e.target[0].value);
        sendPasswordEmail();
    };

    return (
        <div className="min-h-screen flex">
            <div className="flex flex-1 flex-col justify-between py-12 px-4 sm:px-6 xl:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm xl:w-96">
                    <div>
                        <Link to="/">
                            <img className="h-12 w-auto" src="/CTC-Logo-2.png" alt="CheckTheChip" />
                        </Link>
                        {!credId && (
                            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Forgot password?</h2>
                        )}
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">
                            {!credId && (
                                <form onSubmit={submit} className="space-y-6">
                                    <div>
                                        <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                            Please enter your email
                                        </label>
                                        <div className="mt-5 md:col-span-2 md:mt-3">
                                            <div className="relative mt-1 rounded-md shadow-sm">
                                                <div className="pointer-events-none absolute inset-y-0 flex items-center justify-center w-10">
                                                    <EnvelopeIcon
                                                        className="h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div className="relative rounded-md shadow-sm">
                                                    <input
                                                        type="email"
                                                        required
                                                        className="block w-full rounded-md pl-10 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-transparent"
                                                        placeholder="primary@example.com"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 mt-5 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none hover:enabled:bg-indigo-700"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                            {credId && (
                                <label className="block text-lg font-medium text-gray-700">
                                    If you are a registered user, please check your email for a reset password link.
                                </label>
                            )}
                        </div>
                        <p className="mt-10 text-lg  font-medium text-gray-700">
                            Go back to{' '}
                            <Link to="/login" className="text-ctclightblue-500 font-medium hover:text-ctclightblue-400">
                                Log In
                            </Link>
                        </p>
                    </div>
                </div>
                <div className="mx-auto max-w-7xl overflow-hidden px-6 lg:px-8">
                    <CtcLogo />
                    <p className="text-center text-xs leading-5 text-gray-500">
                        &copy; 2024 Check the Chip, Inc. All rights reserved.
                    </p>
                </div>
            </div>

            <div className="hidden flex-1 xl:block">
                <img
                    className="inset-0 h-full w-full object-cover"
                    src="/registration-dog.png"
                    alt="picture of dog with owners arm around it"
                />
            </div>
        </div>
    );
}

export default ForgotPassword;
