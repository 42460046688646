import React, { useState, useRef } from 'react';
import { Input, Button, Card, Divider } from 'antd';
import './styles/Id.css';

const CommonFilterID = ({ name, applyFilters, resetFilters }) => {
    const [kioskID, setKioskID] = useState('');
    const [validToSave, setValidToSave] = useState(false);
    const focusInputRef = useRef(null);

    const applyKioskIDFilter = () => {
        if (!kioskID.trim()) {
            return;
        }
        const dataContainerFilter = {
            kioskID: kioskID.trim(),
        };
        applyFilters(dataContainerFilter, name);
    };

    const resetKioskIDFilter = () => {
        resetFilters();
        setKioskID('');
        setValidToSave(false);
        if (focusInputRef.current) {
            focusInputRef.current.focus();
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setKioskID(value);
        setValidToSave(value.trim().length > 0);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && validToSave) {
            applyKioskIDFilter();
        }
    };

    return (
        <Card className="card-categorical-id">
            <div className="input-wrapper">
                <Input
                    ref={focusInputRef}
                    value={kioskID}
                    placeholder="Enter Kiosk ID"
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    autoFocus
                />
            </div>
            <Divider />
            <div className="button-group">
                <Button className="btn-cancel" onClick={resetKioskIDFilter}>Reset</Button>
                <Button className="btn-apply" disabled={!validToSave} onClick={applyKioskIDFilter}>Apply</Button>
            </div>
        </Card>
    );
};

export default CommonFilterID;
