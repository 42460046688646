import axiosInstance, { getApiService } from '.';

export const getPets = async () => {
    try {
        const result = await axiosInstance.get('/pets');
        return result.data;
    } catch (err) {
        return err.message;
    }
};

export const getPet = async (petId) => {
    try {
        if (!petId) return getPets();

        const result = await axiosInstance.get('/pets/' + petId);
        return result.data;
    } catch (err) {
        return err.message;
    }
};

export const updatePet = async (petId, data) => {
    try {
        if (!petId) return;

        const result = await axiosInstance.put('/pets/' + petId, data);
        return result.data;
    } catch (err) {
        return err.message;
    }
};
