import { Card, Col, Modal, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { CreditCard } from '../../components/CreditCard/CreditCard';
import { useCreditCards } from '../../utility/hooks';
import { StripeCard } from './StripeCard';

export const PaymentMethodsPage = () => {
    const [isCardEddition, setIsCardEddition] = useState(false);

    const {
        creditCards,
        deleteCard,
        updateCard,
        loading,
        fetchCards,
        createCard,
        setLoading,
        errorMessage,
        setErrorMessage,
        success,
        setSuccess,
    } = useCreditCards();

    const closeModal = () => {
        setIsCardEddition(false);
        setSuccess(false);
    };
    const openModal = () => setIsCardEddition(true);

    useEffect(() => {
        fetchCards();
    }, []);

    return (
        <div className="py-2 pl-8 pr-3">
            <Row gutter={32} justify="start" style={{ margin: 0 }}>
                <Col
                    xs={{ flex: '100%' }}
                    sm={{ flex: '100%' }}
                    md={{ flex: '100%' }}
                    lg={{ flex: '100%' }}
                    xl={{ flex: '50%' }}
                >
                    <Card
                        bordered={false}
                        className="relative"
                        styles={{
                            body: {
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            },
                        }}
                    >
                        <Spin spinning={loading}>
                            {creditCards?.map((card) => (
                                <CreditCard
                                    key={card.id}
                                    id={card.id}
                                    isDefault={card.isDefault}
                                    number={card.number}
                                    Expires={`${card?.expMonth}/${card?.expYear.slice(-2)}`}
                                    onRemove={deleteCard}
                                    onClick={updateCard}
                                    brand={card.brand}
                                />
                            ))}
                            {!creditCards.length && !loading && (
                                <div className="flex flex-col justify-center align-center gap-3 mb-5">
                                    <img src="/pets-icon.png" />
                                    <h2 className="text-lg font-medium text-gray-700">
                                        You dont have any payment methods
                                    </h2>
                                </div>
                            )}
                            <button
                                onClick={openModal}
                                type="button"
                                className="inline-flex justify-center items-center gap-x-2 rounded-md border border-ctcdarkblue mt-4 py-2 px-4 text-sm font-medium text-ctcdarkblue shadow-sm hover:bg-ctcdarkblue hover:enabled:bg-ctcdarkblue hover:text-white focus:ring-ctcblue-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ctcdarkblue w-full"
                            >
                                Add Payment Method
                            </button>
                        </Spin>
                    </Card>
                </Col>
            </Row>

            {isCardEddition && (
                <Modal
                    open={isCardEddition}
                    centered
                    title={<h2>Add Payment Method</h2>}
                    onCancel={closeModal}
                    width={600}
                    footer={null}
                    styles={{
                        header: {
                            paddingBottom: 12,
                        },
                        content: {
                            padding: 24,
                        },
                    }}
                >
                    <StripeCard
                        close={closeModal}
                        submit={createCard}
                        loading={loading}
                        setLoading={setLoading}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        success={success}
                    />
                </Modal>
            )}
        </div>
    );
};
