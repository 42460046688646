import React, { Fragment, forwardRef } from 'react';
import { Transition, Menu } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { goToFAQ, goToHelp } from './ExternalGoTo';

const HelpButton = forwardRef(({ onClick, children }, ref) => (
    <button
        className="block px-4 py-2 text-sm hover:text-ctcblue-800 text-ctcblack-600 w-full"
        onClick={onClick}
        ref={ref}
        type="button"
    >
        {children}
    </button>
));

const HelpMenu = () => (
    <Menu as="div" className="relative ml-3 flex align-center justify-center">
        <Menu.Button>
            <div className="flex align-center">
                <QuestionMarkCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </div>
        </Menu.Button>
        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item key="1">
                    <HelpButton onClick={goToFAQ}>FAQs</HelpButton>
                </Menu.Item>
                <Menu.Item key="2">
                    <HelpButton onClick={goToHelp}>Help</HelpButton>
                </Menu.Item>
            </Menu.Items>
        </Transition>
    </Menu>
);

export default HelpMenu;
