import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { EnvelopeIcon } from '@heroicons/react/20/solid';
// Import everything needed to use the `useQuery` hook
import { gql, useLazyQuery } from '@apollo/client';
import { AuthContext } from '../context/AuthContext';
import toast from 'react-hot-toast';
import { CtcLogo } from '../components/CtcLogo';

const FORGOT_USERNAME = gql`
    query forgotUsername($email: String) {
        forgotUsername(email: $email)
    }
`;

function ForgotUsername() {
    const [errors] = useState({});

    const [email, setEmail] = useState();
    const [uname, setUname] = useState(false);
    const context = useContext(AuthContext);

    const onCompletedGetUserName = (data) => {
        //    console.log("HERE WE ARE: ", data.forgotUsername.username);
        setUname(data);
    };

    const onErrorGetUserName = (error) => {
        if (error.message === 'User not found') {
            toast.error(error.message);
        } else {
            toast.error('Error getting data');
        }
        if (error.message === 'Unauthenticated Context') {
            context.logout();
        }
    };

    const [getUserName] = useLazyQuery(FORGOT_USERNAME, {
        variables: { email: String(email) },
        fetchPolicy: 'network-only',
        onCompleted: onCompletedGetUserName,
        onError: onErrorGetUserName,
    });

    const submit = (e) => {
        e.preventDefault();
        setEmail(e.target[0].value);
        getUserName();
    };

    function alertMarkup() {
        if (errors && !errors.length) return null;

        return (
            <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">{`There were errors (${errors.length}) with your submission`}</h3>
                        <div className="mt-2 text-sm text-red-700">
                            <ul className="list-disc space-y-1 pl-5">
                                {errors.map(function (error, index) {
                                    return <li key={index}>{error.message}</li>;
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex">
            <div className="flex flex-1 flex-col justify-between py-12 px-4 sm:px-6 xl:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <Link to="/">
                            <img className="h-12 w-auto" src="/CTC-Logo-2.png" alt="CheckTheChip" />
                        </Link>
                        {!uname && (
                            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Forgot username?</h2>
                        )}
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">
                            {!uname && (
                                <form onSubmit={submit} className="space-y-6">
                                    <div>
                                        <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                            Please enter your email
                                        </label>
                                        <div className="mt-5 md:col-span-2 md:mt-3">
                                            <div className="relative mt-1 rounded-md shadow-sm">
                                                <div className="pointer-events-none absolute inset-y-0 flex items-center justify-center w-10">
                                                    <EnvelopeIcon
                                                        className="h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div className="relative rounded-md shadow-sm">
                                                    <input
                                                        type="email"
                                                        required
                                                        className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-transparent"
                                                        placeholder="primary@example.com"
                                                    />
                                                    {errors.email && (
                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                            <ExclamationCircleIcon
                                                                className="h-5 w-5 text-red-500"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                {errors.email && (
                                                    <p
                                                        role="alert"
                                                        className="mt-2 text-sm text-red-600"
                                                        id="email-error"
                                                    >
                                                        {errors.email?.message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {alertMarkup()}
                                    <div>
                                        <button
                                            type="submit"
                                            className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none hover:enabled:bg-indigo-700"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            )}
                            {uname && (
                                <label className="block text-lg font-medium text-gray-700">
                                    If you are a registered user, please check your email for username
                                </label>
                            )}
                        </div>
                        <p className="mt-10 text-lg  font-medium text-gray-700">
                            Go back to{' '}
                            <Link to="/login" className="text-ctclightblue-500 font-medium hover:text-ctclightblue-400">
                                Log In
                            </Link>
                        </p>
                    </div>
                </div>
                <div className="mx-auto max-w-7xl overflow-hidden px-6 lg:px-8">
                    <CtcLogo />
                    <p className="text-center text-xs leading-5 text-gray-500">
                        &copy; 2024 Check the Chip, Inc. All rights reserved.
                    </p>
                </div>
            </div>
            <div className="hidden flex-1 xl:block">
                <img
                    className="inset-0 h-full w-full object-cover"
                    src="/registration-dog.png"
                    alt="picture of dog with owners arm around it"
                />
            </div>
        </div>
    );
}

export default ForgotUsername;
