import './styles.css';

export const Indicator = ({ active = true }) => {
    return (
        <div className="active-indicator">
            <div
                className={`pulse-circle ${active ? 'bg-green-500 before:bg-green-500' : 'bg-red-500 before:bg-red-500'}`}
            />
        </div>
    );
};
