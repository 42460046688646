import React, { useContext, useState } from 'react';
import { uploadPhoto } from '../../uses/UploadPhoto';
import { AuthContext } from '../../context/AuthContext';
import { CameraOutlined, LoadingOutlined } from '@ant-design/icons';
import { Input, Spin } from 'antd';
import './ProfilePic.css';

const ProfilePic = ({ petdata, petid, type, setImageSubmitted, setFileUploaded, setImage, image }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [caption, setCaption] = useState('');
    const [loading, setLoading] = useState(false);
    const context = useContext(AuthContext);

    const handleFileUpload = async (event) => {
        event.preventDefault();
        const file = event.target?.files[0] || event.file;
        if (!file) {
            console.log('No file selected');
            return;
        }

        setLoading(true);
        setImage(URL.createObjectURL(file));

        const formData = new FormData();
        formData.append('caption', caption);
        formData.append('petid', petid);
        formData.append('type', type);
        formData.append('mime', file.type);
        formData.append('name', file.name);

        try {
            await uploadPhoto(formData, setFileUploaded, setImageSubmitted, setLoading, file, context);
        } finally {
            setLoading(false);
        }
        setIsHovered(false);
    };

    const renderImage = (src) => (
        <div
            className="sm:col-span-1 sm:row-span-1"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="mx-auto relative h-36 w-36 profile-pic-container">
                <img className="h-full w-full object-cover rounded-full" src={src} alt="Pet profile" />
                {loading ? (
                    <div className="absolute inset-0 flex items-center justify-center">
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    </div>
                ) : (
                    <div
                        className={`absolute bottom-0 h-1/2 w-36 rounded-b-full flex items-center justify-center text-white grey-wrapper ${isHovered ? 'grey-container' : 'grey-container-transparent'}`}
                    >
                        <label className="cursor-pointer p-2">
                            {isHovered && <CameraOutlined style={{ fontSize: 24 }} />}
                            <Input
                                type="file"
                                accept="image/jpeg,image/gif,image/png"
                                size="10485760"
                                className="hidden"
                                onChange={handleFileUpload}
                                id="file-input"
                            />
                        </label>
                    </div>
                )}
            </div>
        </div>
    );

    if (image || petdata.imageurlone) {
        return renderImage(image || petdata.imageurlone);
    }

    const placeholders = {
        DOG: '/dog-placeholder.png',
        CAT: '/cat-placeholder.png',
        DEFAULT: '/default-placeholder.png',
    };

    const placeholder = placeholders[petdata.pettype] || placeholders.DEFAULT;

    return renderImage(placeholder);
};

export default ProfilePic;
