import React from 'react';
import moment from 'moment';
import CommonFiltersDate from '../../../components/Common/date';

const FeedFiltersDate = ({ name, setDashboardFilter }) => {
    const applyFilters = (dataContainerFilter, filterName) => {
        const filters = {
            dateFrom: moment(dataContainerFilter.datePrevious).utc().add(1, 'day').startOf('day').toISOString(),
            dateTo: moment(dataContainerFilter.dateNext).utc().endOf('day').toISOString(),
            filterBy: filterName,
        };
        submitChanges(filters, filterName);
    };

    const resetFilters = () => {
        const filters = {
            dateFrom: undefined,
            dateTo: undefined,
            filterBy: undefined,
        };
        submitChanges(filters, name, true);
    };

    const submitChanges = (filters, filterName, isReset=false) => {
        setDashboardFilter(filters, filterName, isReset);
    };

    return (
        <CommonFiltersDate
            name={name}
            applyFilters={applyFilters}
            resetFilters={resetFilters}
            isCalendar={true}
        />
    );
};

export default FeedFiltersDate;
