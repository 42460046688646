import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

function LostPetForm({ setIsOpen, onSubmitChanges }) {
  const navigate = useNavigate();
  let subtitle;
  const inputClass =
    "mt-1 block w-96 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";
  const errorInputClass =
    "mt-1 block w-80 rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm outline-none";

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, event) => {
    console.log("FORM VALUES: ", data);
    onSubmitChanges(data);
  };

  const onError = (errors, e) => {
    console.log({ errors }, { e });
    toast.error("Form is invalid, please check your entries");
  };

  return (
    <>
      <span
        ref={(_subtitle) => (subtitle = _subtitle)}
        className="font-display flex items-center justify-center text-xl font-large"
      >
        Lost Pet Form
      </span>
      <div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className=" px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-1 lg:grid-cols-1">
              <div className="sm:col-span-1">
                <dt className="font-display text-sm font-medium text-black">
                  Describe what happened (please be as specific as possible)*
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <textarea
                    placeholder="We were at Burton Park in the large field area going for a walk. Brownie saw a squirrel and ran off. He still has his red leash attached. He headed North."
                    id="lostdescription"
                    {...register("lostdescription", {
                      required: "Description of the lost event is required",
                    })}
                    maxLength="1000"
                    rows={4}
                    defaultValue={""}
                    className={
                      errors.lostdescription ? errorInputClass : inputClass
                    }
                  />
                  {errors.lostdescription ? (
                    <div className="pointer-events-none absolute right-8 flex items-center pr-3"></div>
                  ) : null}
                </dd>
                {errors.lostdescription && (
                  <p
                    role="alert"
                    className="mt-2 text-sm text-red-600"
                    id="lostdescription-error"
                  >
                    {errors.lostdescription?.message}
                  </p>
                )}
              </div>

              <div className="sm:col-span-1">
                <dt className="font-display text-sm font-medium text-black">
                  What address is closest to the last known location?*
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <input
                    type="text"
                    placeholder="900 Chestnut St, San Carlos, CA 94070"
                    id="lostlocation"
                    {...register("lostlocation", {
                      required: "Last known location is required",
                    })}
                    maxLength="128"
                    defaultValue={""}
                    className={
                      errors.lostlocation ? errorInputClass : inputClass
                    }
                  />
                  {errors.lostlocation ? (
                    <div className="pointer-events-none absolute right-8 flex items-center pr-3"></div>
                  ) : null}
                </dd>
                {errors.lostlocation && (
                  <p
                    role="alert"
                    className="mt-2 text-sm text-red-600"
                    id="lostlocation-error"
                  >
                    {errors.lostlocation?.message}
                  </p>
                )}
              </div>

              <div className="sm:col-span-1">
                <dt className="font-display text-sm font-medium text-black">
                  Time of last sighting
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <input
                    type="datetime-local"
                    id="lostdatetime"
                    {...register("lostdatetime")}
                    className={inputClass}
                  />
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="font-display text-sm font-medium text-black">
                  Anything else we should know?
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <textarea
                    placeholder="Brownie is scared of cars, please help."
                    id="lostother"
                    {...register("lostother")}
                    maxLength="1000"
                    rows={2}
                    defaultValue={""}
                    className={inputClass}
                  />
                </dd>
              </div>
            </dl>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-ctcdarkblue py-2 px-4 text-sm font-medium text-ctcdarkblue shadow-sm hover:bg-ctcblack-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-ctcdarkblue focus:ring-offset-2 disabled:opacity-25"
              >
                Submit
              </button>
              <button
                onClick={(e) => {
                  setIsOpen(false);
                }}
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-ctcblue-500 focus:ring-offset-2 disabled:opacity-25"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default LostPetForm;
