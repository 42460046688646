import React, { useState, useRef } from 'react';
import moment from 'moment';
import { DatePicker, Button, Card, Divider } from 'antd';
import './styles/Date.css';

const { RangePicker } = DatePicker;

const CommonFiltersDate = ({ name, applyFilters, resetFilters }) => {
    const [dates, setDates] = useState([null, null]);
    const [formatDates, setFormatDates] = useState(['', '']);
    const [validToSave, setValidToSave] = useState(false);
    const pickerRef = useRef(null);

    const formatDate = (date, format) => date ? moment(date).format(format) : '';

    const changeDatesToFrom = (date, type) => {
        const newDate = moment(date).format('YYYY-MM-DD');
        if (date && newDate) {
            const firstDate = type === 'to' ? dates[0] : newDate;
            const secondDate = type === 'to' ? newDate : dates[0];
            setDates([firstDate, secondDate]);
        }
        if (!newDate) {
            setFormatDates([formatDate(dates[0], 'L'), formatDate(dates[1], 'L')]);
        }
    };

    const apply = () => {
        if (formatDates.length === 0) {
            return;
        }
        const dateNext = formatDates[0] === formatDates[1] ? undefined : formatDates[1];
        const dataContainerFilter = {
            datePrevious: moment(formatDates[0]).startOf('day').format(),
            dateNext: moment(formatDates[1]).endOf('day').format(),
        };
        applyFilters(dataContainerFilter, name);
    };

    const reset = () => {
        resetFilters();
        setDates([null, null]);
        setFormatDates(['', '']);
        setValidToSave(false);
    };

    const setDat = (dateStrings) => {
        setFormatDates(dateStrings.map(date => date.toString()));
        setValidToSave(true);
    };

    return (
        <Card className="card-categorical">
            <div className="input-wrapper">
                <RangePicker
                    ref={pickerRef}
                    value={dates}
                    onChange={(dates, dateStrings) => {
                        setDates(dates);
                        setDat(dateStrings);
                    }}
                    style={{ marginTop: '20px', width: '100%' }}
                    picker="date"
                    allowClear={true}
                />
            </div>
            <Divider />
            <div className="button-group">
                <Button className="btn-cancel" onClick={reset}>Reset</Button>
                <Button className="btn-apply" disabled={!validToSave} onClick={apply}>Apply</Button>
            </div>
        </Card>
    );
};

export default CommonFiltersDate;
