import React, { useState } from 'react';
import Toolbar from '../components/Toolbar';
import Modal from '../components/Modal';
import { ExclamationCircleIcon, XCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { useMutation, gql } from '@apollo/client';
import { useForm } from '../utility/hooks';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { CtcLogo } from '../components/CtcLogo';
import { goToTOS, goToPrivacyPolicy } from '../components/ExternalGoTo';

const REGISTER = gql`
    mutation registerCustomerPortal(
        $token: String!
        $newusername: String!
        $newpassword: String!
        $newpassword2: String!
        $agree: Boolean!
        $kioskemailid: Int!
    ) {
        registerCustomerPortal(
            token: $token
            newusername: $newusername
            newpassword: $newpassword
            newpassword2: $newpassword2
            agree: $agree
            kioskemailid: $kioskemailid
        ) {
            email
        }
    }
`;

function Register({ setUser, user }) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get('token');
    const kioskemailid = Number(params.get('kioskemailid'));

    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showPasswordOne, setShowPasswordOne] = useState(false);
    const [showPasswordTwo, setShowPasswordTwo] = useState(false);

    const [agree, setAgree] = useState(false);

    let navigate = useNavigate();

    const { onChange, onSubmit, values } = useForm(loginUserCallback, {
        token: tokenParam,
        kioskemailid: kioskemailid,
        newusername: '',
        newpassword: '',
        newpassword2: '',
        agree: false,
    });
    function loginUserCallback() {
        register();
    }

    const handleAgreeChange = (e) => {
        setAgree(e.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (agree === false) {
            setShowModal(true);
            return;
        }
        onSubmit(e);
        // window.location.href = "https://checkthechip.com/subtest";
    };

    const [register /*, { data, loading, error } */] = useMutation(REGISTER, {
        update(proxy, { data: { registerCustomerPortal: userData } }) {
            //context.login(userData);
            setErrors([]);
            toast.success('Registration successful');
            navigate('/login');
        },
        onError(err) {
            const graphQLErrors = err.graphQLErrors;
            const networkError = err.networkError;
            if (graphQLErrors && graphQLErrors.length > 0) {
                setErrors(graphQLErrors);
            }
            if (networkError) {
                setErrors([networkError]);
            }
        },
        variables: {
            fetchPolicy: 'network-only',
            token: tokenParam,
            kioskemailid: kioskemailid,
            newusername: values.newusername,
            newpassword: values.newpassword,
            newpassword2: values.newpassword2,
            agree: agree,
        },
    });

    function alertMarkup() {
        if (errors && !errors.length) return null;

        return (
            <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">{`There were errors (${errors.length}) with your submission`}</h3>
                        <div className="mt-2 text-sm text-red-700">
                            <ul className="list-disc space-y-1 pl-5">
                                {errors.map(function (error, index) {
                                    return <li key={index}>{error.message}</li>;
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function loginMarkup() {
        const validInputClasses = 'block w-full shadow-sm sm:text-sm border-gray-300 rounded-md';
        const invalidInputClasses =
            'block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm';
        return (
            <>
                <div className="flex min-h-full">
                    <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                        <div className="mx-auto w-full max-w-sm lg:w-96">
                            <div>
                                <img className="h-12 w-auto" src="/CTC-Logo-2.png" alt="CheckTheChip" />
                                <h2 className="mt-6 text-3xl text-ctcblue-600 font-bold tracking-tight">
                                    Create an account
                                </h2>
                                <div className="block mt-4 text-sm text-gray-500">
                                    You are joining a community of pet owners{' '}
                                    <span className="underline">obsessed</span> with pet safety. Let's get started!
                                </div>
                            </div>

                            <div className="mt-8">
                                <div className="mt-6">
                                    <form action="#" method="POST" className="space-y-6">
                                        <div>
                                            <label
                                                htmlFor="newusername"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Create a Username
                                            </label>
                                            <div className="relative mt-1 rounded-md shadow-sm">
                                                <input
                                                    id="newusername"
                                                    name="newusername"
                                                    placeholder="3-15 length, no special characters"
                                                    type="text"
                                                    required
                                                    autoComplete="no-autocomplete"
                                                    onChange={onChange}
                                                    className={
                                                        errors && errors.newusername
                                                            ? invalidInputClasses
                                                            : validInputClasses
                                                    }
                                                />
                                            </div>
                                            {errors && errors.username ? (
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <ExclamationCircleIcon
                                                        className="h-5 w-5 text-red-500"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                        <div
                                            className="space-y-1"
                                            tooltip="Password must be at least 8 characters long, contain one uppercase, one lowercase, one digit, and one special character"
                                        >
                                            <label
                                                htmlFor="newpassword"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Password
                                            </label>
                                            <div className="mt-1 flex">
                                                <input
                                                    id="newpassword"
                                                    name="newpassword"
                                                    placeholder="Min 8 incl 1 uppercase, 1 lowercase, 1 digit, 1 special char"
                                                    type={showPasswordOne ? 'text' : 'password'}
                                                    autoComplete="no-autocomplete"
                                                    required
                                                    onChange={onChange}
                                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                />
                                                {showPasswordOne ? (
                                                    <EyeIcon
                                                        className="h-6 w-6 text-gray-500 m-2"
                                                        aria-hidden="true"
                                                        onClick={() => setShowPasswordOne((prevState) => !prevState)}
                                                    />
                                                ) : (
                                                    <EyeSlashIcon
                                                        className="h-6 w-6 text-gray-500 m-2"
                                                        aria-hidden="true"
                                                        onClick={() => setShowPasswordOne((prevState) => !prevState)}
                                                    />
                                                )}
                                            </div>
                                        </div>

                                        <div className="space-y-1">
                                            <label
                                                htmlFor="newpassword2"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Retype password
                                            </label>
                                            <div className="mt-1 flex">
                                                <input
                                                    id="newpassword2"
                                                    name="newpassword2"
                                                    placeholder="Passwords must match"
                                                    type={showPasswordTwo ? 'text' : 'password'}
                                                    autoComplete="no-autocomplete"
                                                    required
                                                    onChange={onChange}
                                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                />
                                                {showPasswordTwo ? (
                                                    <EyeIcon
                                                        className="h-6 w-6 text-gray-500 m-2"
                                                        aria-hidden="true"
                                                        onClick={() => setShowPasswordTwo((prevState) => !prevState)}
                                                    />
                                                ) : (
                                                    <EyeSlashIcon
                                                        className="h-6 w-6 text-gray-500 m-2"
                                                        aria-hidden="true"
                                                        onClick={() => setShowPasswordTwo((prevState) => !prevState)}
                                                    />
                                                )}
                                            </div>
                                        </div>

                                        {alertMarkup()}

                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                <input
                                                    id="agree"
                                                    name="agree"
                                                    type="checkbox"
                                                    checked={agree}
                                                    onChange={handleAgreeChange}
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                />
                                                <label htmlFor="agree" className="ml-2 block text-sm text-gray-900">
                                                    By checking this box you confirm that you agree to the{' '}
                                                    <Link
                                                        onClick={goToTOS}
                                                        className="text-ctcblue-600 font-medium hover:text-ctcblue-400"
                                                    >
                                                        Terms & Conditions
                                                    </Link>{' '}
                                                    and{' '}
                                                    <Link
                                                        onClick={goToPrivacyPolicy}
                                                        className="text-ctcblue-600 font-medium hover:text-ctcblue-400"
                                                    >
                                                        Privacy Policy
                                                    </Link>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                onClick={handleSubmit}
                                                className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Create my account
                                            </button>
                                        </div>
                                        <p className="mt-2 text-sm text-gray-900">
                                            Already have an account?{' '}
                                            <a
                                                href="/login"
                                                className="text-ctcblue-600 font-medium hover:text-ctcblue-400"
                                            >
                                                Log in
                                            </a>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative hidden w-0 flex-1 lg:block">
                        <img
                            className="absolute inset-0 h-full w-full object-cover"
                            src="/registration-dog.png"
                            alt="dog with owners arm around it"
                        />
                    </div>
                </div>
                <footer className="bg-white">
                    <div className="mx-auto max-w-7xl overflow-hidden px-6 py-10 sm:py-12 lg:px-8">
                        <CtcLogo />
                        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
                            &copy; 2024 Check the Chip, Inc. All rights reserved.
                        </p>
                    </div>
                </footer>
            </>
        );
    }

    return (
        <>
            <Toolbar user={user} setUser={setUser}></Toolbar>
            <Modal
                open={showModal}
                setOpen={setShowModal}
                title="Accept Terms"
                message="You must indicate that you accept our Terms & Conditions and our Privacy Policy by checking the box in order to continue"
                action="Go Back to Registration"
                mode="error"
            ></Modal>
            {loginMarkup()}
        </>
    );
}

export default Register;
