import React, { useState } from 'react';
import { Dropdown, Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import './styles/FilterBaseLine.css';

const FiltersBaseLine = ({ filtersTypes, isDisabled, setDashboardFilter }) => {
    const [isMenu, setIsMenu] = useState({});
    const [isClicked, setIsClicked] = useState('');
    const [activeFilter, setActiveFilter] = useState({});

    const setActive = (filter) => {
        setIsClicked(filter.value);
        setIsMenu({ ...isMenu, [filter.value]: !isMenu[filter.value] });
    };

    const close = (name, filters) => {
        setIsClicked('');
        setIsMenu({});
        filtersTypes.forEach((v) => {
            if (v.value === name) {
                v.isActive = !!filters.filterBy;
            }
        });
    };

   const setFilter = (filter, filterName, isReset= false) => {
       setDashboardFilter(filter, filterName)
       close(filter.value, filter)
       const upActiveFilter = {
           ...activeFilter,
           [filterName]: !isReset
       }
       setActiveFilter(upActiveFilter);
    }

    return (
        <div className="mx-1 base-line-container">
            <div className="vertical-line"></div>
            <div className="head mx-5">Filters</div>
            {filtersTypes.map((filter, index) => (
                <div key={index + 1}>
                    {filter.type === 'categorical' ? (
                        <Dropdown
                            overlay={
                                <div className="dropdown-content">
                                    <filter.component
                                        filter={filter}
                                        name={filter.value}
                                        onClose={() => close(filter.value, filter)}
                                        setDashboardFilter={setFilter}
                                    />
                                </div>
                            }
                            trigger={['click']}
                            visible={isMenu[filter.value]}
                            onVisibleChange={() => setIsMenu({ ...isMenu, [filter.value]: !isMenu[filter.value] })}
                        >
                            <Button
                                disabled={isDisabled}
                                onClick={() => setActive(filter)}
                                className={`wrap-filter sentiment ${isClicked === filter.value ? 'active' : ''}`}
                            >
                                {filter.title}
                                {activeFilter[filter.value] && <CheckOutlined  style={{ color: '#08c' }} />}
                            </Button>
                        </Dropdown>
                    ) : (
                        <Button
                            className={`wrap-filter ${filter.isActive ? 'active' : ''}`}
                            onClick={() => setActive(filter)}
                        >
                            {filter.title}
                        </Button>
                    )}
                </div>
            ))}
        </div>
    );
};

export default FiltersBaseLine;
