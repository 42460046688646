const websiteURL = process.env.WEBSITE_URL || "https://www.checkthechip.com";

const makeExternalGoto = (url) => () => {
  window.open(url, "_blank");
};

const makeGotoWebsite = (path) => makeExternalGoto(new URL(path, websiteURL));

const goToFAQ = makeGotoWebsite("/cp-faq");
const goToHelp = makeGotoWebsite("/contact");
const goToReportPetAsLost = makeGotoWebsite("/lost-pet");
const goToPrivacyPolicy = makeGotoWebsite("/privacy-policy");
const goToTOS = makeGotoWebsite("/terms-conditions");

module.exports = {
  goToFAQ,
  goToHelp,
  goToReportPetAsLost,
  goToPrivacyPolicy,
  goToTOS,
};
