import { useContext, useEffect, useState } from 'react';
import { getPets } from '../../utility/api/pets';
import toast from 'react-hot-toast';
import { AuthContext } from '../../context/AuthContext';
import { Button, Modal, Table } from 'antd';
import { formatDate } from '../../helpers/helpers';
import { useCreditCards } from '../../utility/hooks';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { CreditCard } from '../../components/CreditCard/CreditCard';
import { Loader } from '../../components/Loader';
import { StripeCard } from '../PaymentMethods/StripeCard';
import { PetAvatar } from '../../components/PetAvatar';

export const BillingPage = () => {
    const context = useContext(AuthContext);
    const {
        creditCards,
        loading: paymentLoading,
        fetchCards,
        payWithMethodId,
        createAndPay,
        setLoading: setPaymentLoading,
        errorMessage,
        setErrorMessage,
        success,
        setSuccess,
    } = useCreditCards();
    const [isOpenedPayment, setIsOPenedPayment] = useState(false);
    const [isAddingNewMethod, setIsAddingNewMethod] = useState(creditCards.length === 0 ? true : false);
    const [selectedCard, setSelectedCard] = useState('');
    const close = () => {
        setIsOPenedPayment(false);
        setIsAddingNewMethod(creditCards.length === 0 ? true : false);
        setSelectedCard(creditCards?.find((card) => card.isDefault)?.id || '');
        setCurrentPet(null);
        setSuccess(false);
    };
    const open = async (pet) => {
        setIsAddingNewMethod(creditCards.length === 0 ? true : false);
        setCurrentPet(pet);
        setSelectedCard(creditCards?.find((card) => card.isDefault)?.id || '');
        setIsOPenedPayment(true);
    };
    const getCards = async () => {
        await fetchCards();
        setSelectedCard(creditCards?.find((card) => card.isDefault)?.id || '');
    };
    const selectCardForPay = (id) => setSelectedCard(id);

    const [loading, setLoading] = useState(false);
    const [pets, setPets] = useState([]);
    const [currentPet, setCurrentPet] = useState(null);

    const onErrorGetMyPets = (error) => {
        toast.error('Error getting data for pet menu');
        if (error.message === 'Unauthenticated Context') {
            context.logout();
        }
    };

    const fetchData = async () => {
        const res = await getPets();

        if (res.pets) {
            return setPets(res.pets);
        }

        onErrorGetMyPets(res);
    };

    useEffect(() => {
        setLoading(true);
        fetchData().finally(() => setLoading(false));
        getCards();
    }, []);

    return (
        <div className="py-2 pl-8 pr-3 w-full">
            <Table
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        render: (_, record) => (
                            <div className="flex items-center gap-2">
                                <PetAvatar pet={record} />
                                <p>{record?.name}</p>
                            </div>
                        ),
                    },
                    {
                        title: 'Billing Payment Date',
                        dataIndex: 'registrationdate',
                        render: (_, record) =>
                            record?.billing?.billingDate ? (
                                formatDate(record?.billing?.billingDate)
                            ) : (
                                <p className="italic">Not received</p>
                            ),
                    },
                    {
                        title: 'Amount',
                        render: (_, record) => '$49',
                        align: 'center',
                    },
                    {
                        title: 'Paid Status',
                        dataIndex: 'registrationstatus',
                        render: (_, record) =>
                            record?.billing?.status === 'paid' ? (
                                <p className="text-green-600">Paid</p>
                            ) : (
                                <p className="text-red-600">Unpaid</p>
                            ),
                        align: 'center',
                    },
                    {
                        title: 'Payment Method',
                        render: (_, record) =>
                            record?.billing?.status === 'paid' ? (
                                <div>
                                    Credit Card ({record?.billing?.brand} {record?.billing?.number})
                                </div>
                            ) : (
                                <button
                                    onClick={() => {
                                        open(record);
                                    }}
                                    className="underline text-b text-sm font-medium text-gray-700 focus:outline-none hover:enabled:text-ctclightblue-500 hover:enabled:bg-transparent"
                                >
                                    Submit Payment Here
                                </button>
                            ),
                    },
                ]}
                dataSource={pets}
                loading={loading}
                pagination={false}
            />

            {isOpenedPayment && (
                <Modal
                    open={isOpenedPayment}
                    centered
                    title={
                        isAddingNewMethod ? (
                            creditCards.length && (
                                <Button
                                    className="text-ctclightblue-500 hover:text-ctclightblue-900"
                                    data-tooltip-id="edit"
                                    type="text"
                                    shape="circle"
                                    icon={<ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />}
                                    onClick={() => setIsAddingNewMethod(false)}
                                />
                            )
                        ) : (
                            <h2 className="text-lg font-medium text-gray-700">Pay</h2>
                        )
                    }
                    onCancel={close}
                    width={600}
                    footer={null}
                    styles={{
                        header: {
                            paddingBottom: 12,
                        },
                        content: {
                            padding: 24,
                        },
                    }}
                >
                    <div className="flex flex-col gap-3">
                        <h2 className="text-lg font-medium text-gray-700 text-center">
                            Select a payment method or use other method to pay for {currentPet?.name || 'your pet'}.
                        </h2>

                        {isAddingNewMethod ? (
                            <StripeCard
                                close={close}
                                submit={createAndPay}
                                loading={paymentLoading}
                                setLoading={setPaymentLoading}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                                text={'Pay $49'}
                                type="pay"
                                success={success}
                                petId={currentPet?.id}
                                callback={fetchData}
                            />
                        ) : (
                            <div>
                                {creditCards?.length ? (
                                    creditCards?.map((card) => (
                                        <CreditCard
                                            key={card.id}
                                            id={card.id}
                                            isDefault={card.isDefault}
                                            number={card.number}
                                            Expires={`${card?.expMonth}/${card?.expYear.slice(-2)}`}
                                            onClick={selectCardForPay}
                                            brand={card.brand}
                                            isPay
                                            selectedCard={selectedCard}
                                        />
                                    ))
                                ) : (
                                    <div className="flex flex-col justify-center align-center gap-3 mb-5 mt-5">
                                        <img src="/pets-icon.png" />
                                        <h2 className="text-lg font-medium text-gray-700">
                                            You dont have any payment methods
                                        </h2>
                                    </div>
                                )}

                                <div onClick={() => setIsAddingNewMethod(true)} className="flex justify-end">
                                    <button className="text-b py-2 px-8 text-sm font-medium text-gray-700 focus:outline-none hover:enabled:text-ctclightblue-500 hover:enabled:bg-white">
                                        Use other payment method
                                    </button>
                                </div>
                            </div>
                        )}

                        {!isAddingNewMethod && (
                            <button
                                onClick={async () => {
                                    await payWithMethodId(selectedCard, currentPet.id);
                                    await fetchData();
                                    close();
                                }}
                                disabled={!selectedCard}
                                className="w-full inline-flex justify-center items-center rounded-md border border-transparent bg-indigo-600 py-2 px-8 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none
                            hover:disabled:bg-gray-300 
                            hover:enabled:bg-indigo-700"
                            >
                                {!paymentLoading ? 'Pay $49' : <Loader />}
                            </button>
                        )}
                    </div>
                </Modal>
            )}
        </div>
    );
};
