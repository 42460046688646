import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import UploadImageTarget from '../pages/PetProfile/UploadImageTarget';
import CompleteUpload from '../pages/PetProfile/CompleteUpload';

export default function PhotoUploadModal({
    open,
    setOpen,
    petid,
    type,
    image,
    setImage,
    setImageSubmitted,
    updatePetProfile,
}) {
    const [fileUploaded, setFileUploaded] = useState(false);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-[20px] bg-white text-left shadow-xl transition-all">
                                {fileUploaded ? (
                                    <CompleteUpload
                                        onClick={() => {
                                            setOpen(false);
                                            setFileUploaded(false);
                                        }}
                                    />
                                ) : (
                                    <UploadImageTarget
                                        setFileUploaded={setFileUploaded}
                                        petid={petid}
                                        type={type}
                                        image={image}
                                        setImage={setImage}
                                        setOpen={setOpen}
                                        setImageSubmitted={setImageSubmitted}
                                        updatePetProfile={updatePetProfile}
                                    />
                                )}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
