import React, { useState, useContext } from 'react';
import { Link, useLocation, useNavigation, useParams } from 'react-router-dom';
import { ExclamationCircleIcon, XCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { useMutation, gql } from '@apollo/client';
import { AuthContext } from '../context/AuthContext';
import { useForm } from '../utility/hooks';
import { useNavigate } from 'react-router-dom';
import { CtcLogo } from '../components/CtcLogo';

const LOGIN = gql`
    mutation login($username: String!, $password: String!) {
        login(username: $username, password: $password, rememberme: false) {
            accesstoken
            username
            userid
        }
    }
`;

const LOGIN_ADMIN = gql`
    mutation loginAdmin($username: String!, $password: String!) {
        loginAdmin(username: $username, password: $password, rememberme: false) {
            accesstoken
            username
            admin_id
        }
    }
`;

function Login({ setUser, user, isAdminRoute }) {
    const [errors, setErrors] = useState({});
    const context = useContext(AuthContext);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const { onChange, onSubmit, values } = useForm(loginUserCallback, {
        username: localStorage.getItem('cpusername') || '',
        password: '',
        rememberme: getBooleanFromLocalStorage('cprememberMe'),
    });

    function getBooleanFromLocalStorage(key) {
        const storedValue = localStorage.getItem(key);
        return storedValue === 'true';
    }

    function loginUserCallback() {
        login();
    }
    const onPasswordKeydown = (event) => {
        if (event.code === 'Enter') {
            onSubmit(event);
        }
    };

    const [login, { loading }] = useMutation(isAdminRoute ? LOGIN_ADMIN : LOGIN, {
        update(proxy, { data }) {
            const userData = isAdminRoute ? data.loginAdmin : data.login;
            context.login(userData);
            setErrors([]);
            const user = {
                ...userData,
                is_admin: !!(isAdminRoute && userData.admin_id),
            };

            if (
                [
                    '/register',
                    '/forgotusername',
                    '/forgotpassword',
                    '/resetpassword',
                    '/login',
                    '/admin',
                    undefined,
                ].includes(location?.state?.from)
            ) {
                user.is_admin ? navigate('/ctc/dashboard') : navigate('/ctc/petprofiles');
                return;
            } else {
                navigate(-1);
            }

            // if (location?.key === 'default') {
            //     user.is_admin ? navigate('/ctc/dashboard') : navigate('/ctc/petprofiles');
            // } else {
            //     navigate(-1);
            // }
        },
        onError(err) {
            const graphQLErrors = err.graphQLErrors;
            const networkError = err.networkError;
            if (graphQLErrors && graphQLErrors.length > 0) {
                setErrors(graphQLErrors);
            }
            if (networkError) {
                setErrors([networkError]);
            }
        },
        variables: {
            username: values.username,
            password: values.password,
        },
    });

    function alertMarkup() {
        if (!errors || !errors.length) return null;
        return (
            <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">{`There were errors (${errors.length}) with your submission`}</h3>
                        <div className="mt-2 text-sm text-red-700">
                            <ul className="list-disc space-y-1 pl-5">
                                {errors.map((error, index) => (
                                    <li key={index}>{error.message}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const validInputClasses = 'block w-full shadow-sm sm:text-sm border-gray-300 rounded-md';
    const invalidInputClasses =
        'block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm';

    return (
        <div className="min-h-screen flex">
            <div className="flex flex-1 flex-col justify-between py-12 px-4 sm:px-6 xl:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <Link to="/">
                            <img className="h-12 w-auto" src="/CTC-Logo-2.png" alt="CheckTheChip" />
                        </Link>
                        <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                            Sign in to your {isAdminRoute ? 'Admin ' : ''}account
                        </h2>
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">
                            <form action="#" method="POST" className="space-y-6">
                                <div>
                                    <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                        Username
                                    </label>
                                    <div className="relative mt-1 rounded-md shadow-sm">
                                        <input
                                            id="username"
                                            name="username"
                                            type="text"
                                            required
                                            autoComplete="no-autocomplete"
                                            onChange={onChange}
                                            value={values.username}
                                            className={errors.username ? invalidInputClasses : validInputClasses}
                                        />
                                    </div>
                                    {errors.username && (
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                            <ExclamationCircleIcon
                                                className="h-5 w-5 text-red-500"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    )}
                                </div>
                                <p className="mt-2 text-sm text-right text-gray-600">
                                    <Link
                                        to="/forgotusername"
                                        className="font-medium text-indigo-600 hover:text-indigo-400"
                                    >
                                        Forgot username?
                                    </Link>
                                </p>
                                <div className="space-y-1">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Password
                                    </label>
                                    <div className="mt-1 flex relative">
                                        <input
                                            id="password"
                                            name="password"
                                            type={showPassword ? 'text' : 'password'}
                                            autoComplete="no-autocomplete"
                                            required
                                            onChange={onChange}
                                            onKeyDown={onPasswordKeydown}
                                            value={values.password}
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-ctcblue-500 focus:outline-none focus:ring-ctcblue-500 sm:text-sm"
                                        />
                                        {showPassword ? (
                                            <EyeIcon
                                                className="h-6 w-6 text-gray-500 m-2 cursor-pointer absolute right-1"
                                                aria-hidden="true"
                                                onClick={() => setShowPassword(!showPassword)}
                                            />
                                        ) : (
                                            <EyeSlashIcon
                                                className="h-6 w-6 text-gray-500 m-2 cursor-pointer absolute right-1"
                                                aria-hidden="true"
                                                onClick={() => setShowPassword(!showPassword)}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-right justify-end">
                                    <div className="text-sm">
                                        <Link
                                            to="/forgotpassword"
                                            className="font-medium text-indigo-600 hover:text-indigo-400"
                                        >
                                            Forgot password?
                                        </Link>
                                    </div>
                                </div>
                                {alertMarkup()}
                                <div>
                                    <button
                                        type="button"
                                        onClick={onSubmit}
                                        disabled={loading}
                                        className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 mt-5 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none hover:enabled:bg-indigo-700"
                                    >
                                        Sign in
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="mx-auto max-w-7xl overflow-hidden px-6 lg:px-8">
                    <CtcLogo />
                    <p className="text-center text-xs leading-5 text-gray-500">
                        &copy; 2024 Check the Chip, Inc. All rights reserved.
                    </p>
                </div>
            </div>

            <div className="hidden flex-1 xl:block">
                <img
                    className="inset-0 h-full w-full object-cover"
                    src="/registration-dog.png"
                    alt="picture of dog with owners arm around it"
                />
            </div>
        </div>
    );
}

export default Login;
