// eventually, this file will always be generated at deploy time.
// for now, it is the defaults
export const GraphQlURL = 'http://localhost:4000/graphql';
export const ApiURL = 'http://localhost:4000/api/';
export const environment = {
    apiUrl: 'http://localhost:4000',
    STRIPE_PUBLIC_KEY:
        'pk_test_51Pb5T82NnjdQfBqFKBnsli3aW3ju8i72vaIgxKQmUOxeUmpq4Ah6HjmXfKmhmx4HLvavvVbLvW7ecGamf60ULCIO00fEdVMfle',
    GraphQlURL: '/graphql',
    ApiPostfix: '/api',
};
