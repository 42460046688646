import { InformationCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { Indicator } from '../../components/Indicator/Indicator';

function LostReport({ pet, openLostPetModal }) {
    if (!pet) return null;

    return (
        <div className="flex flex-col sm:items-start items-center">
            <div className="flex-grow text-xl font-large">{pet.name}</div>

            <div className="flex items-center gap-3">
                <p className="text-gray-600 font-medium">Registration status</p>
                <Indicator active={pet?.registrationstatus?.toLowerCase() === 'active' ? true : false} />
            </div>

            <dt className="font-display flex items-center text-sm font-medium text-black mt-2">
                Message To Finder
                <InformationCircleIcon
                    data-tooltip-id="finder-msg"
                    data-tooltip-content="This message will be displayed on the kiosk if your lost pet is found and scanned at the kiosk."
                    className="-mr-0.5 ml-0.5 h-5 w-5"
                    aria-hidden="true"
                />
                <Tooltip
                    id="finder-msg"
                    place="right"
                    border="1px solid #36A5FF"
                    style={{
                        backgroundColor: 'rgb(255, 255, 255)',
                        width: '30%',
                        color: '#3092e3',
                    }}
                />
            </dt>

            <dd className="mt-1 mb-5 text-sm text-gray-900">{pet.findermessage}</dd>
            {
                <button
                    type="button"
                    onClick={openLostPetModal}
                    className="inline-flex justify-center items-center gap-x-2 rounded-md border border-ctcdarkblue py-2 px-4 text-sm font-medium text-ctcdarkblue shadow-sm hover:bg-ctcblack-100 hover:text-white focus:ring-ctcblue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ctcdarkblue"
                >
                    Report as lost!
                </button>
            }
        </div>
    );
}

export default LostReport;
