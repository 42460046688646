import React, { useState } from 'react';
import { Button, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import './styles/PetPhoto.css';

function PetPhoto({ imageUrl, type, setOpen, setOpenType, handleRemovePhoto, isEditPage = false, loading = true }) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className="photo-container"
            style={{
                width: '250px',
                height: '250px',
                position: 'relative',
                padding: '5px',
                border: isHovered && !imageUrl ? '1px solid #09278B' : '1px solid grey',
                borderRadius: '8px',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Spin spinning={loading} tip="Loading...">
                {imageUrl ? (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '240px',
                                borderRadius: '8px',
                                overflow: 'hidden',
                            }}
                        >
                            <img src={imageUrl} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>

                        {isHovered && (
                            <div
                                className="photo-overlay"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '10px',
                                    borderRadius: '8px',
                                }}
                            >
                                <div className="replace-photo-btn">
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            setOpenType(type);
                                            setOpen(true);
                                        }}
                                        className="inline-flex justify-center items-center gap-x-2 rounded-md border border-ctcblack-500 background-ctcwhite py-2 px-4 text-sm font-medium text-ctcdarblack shadow-sm hover:bg-ctcblack-100 hover:text-white focus:ring-ctcblue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ctcdarkblue"
                                    >
                                        Replace Image
                                    </button>
                                </div>
                                {!isEditPage && (
                                    <Button
                                        icon={<DeleteOutlined color="#000000" />}
                                        shape="circle"
                                        onClick={(e) => {
                                            handleRemovePhoto(type);
                                        }}
                                        className="remove-photo-btn hover:bg-gray-50"
                                        style={{
                                            backgroundColor: '#ffffff',
                                            borderColor: '#ffffff',
                                            color: '#000000',
                                            marginLeft: '10px',
                                        }}
                                        onMouseOver={(e) => {
                                            e.currentTarget.style.backgroundColor = '#09278b';
                                            e.currentTarget.style.color = '#fff';
                                        }}
                                        onMouseOut={(e) => {
                                            e.currentTarget.style.backgroundColor = '#ffffff';
                                            e.currentTarget.style.color = '#000000';
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </>
                ) : (
                    <div
                        className="no-image-container"
                        style={{
                            width: '100%',
                            height: '237px',
                            borderRadius: '8px',
                            backgroundColor: isHovered ? '#d0e7ff' : '#ffffff',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={(e) => {
                            setOpenType(type);
                            setOpen(true);
                        }}
                    >
                        <img className="h-12 w-auto" src="/pets-icon.png" alt="CheckTheChip" />
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenType(type);
                                setOpen(true);
                            }}
                            type="link"
                            style={{
                                color: isHovered ? '#09278B' : '#0a0a0a',
                                textDecoration: isHovered ? 'underline' : 'none',
                                marginTop: '10px',
                            }}
                        >
                            Add an image
                        </Button>
                    </div>
                )}
            </Spin>
        </div>
    );
}

export default PetPhoto;
