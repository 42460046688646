import React, { useState } from 'react';
import { XCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
// Import everything needed to use the `useQuery` hook
import { useMutation, gql } from '@apollo/client';
import { useForm } from '../utility/hooks';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { CtcLogo } from '../components/CtcLogo';

const RESET_PASSWORD = gql`
    mutation resetPassword($id: Int!, $token: String!, $dt: String!, $newpassword: String!, $newpassword2: String!) {
        resetPassword(id: $id, token: $token, dt: $dt, newpassword: $newpassword, newpassword2: $newpassword2)
    }
`;

function ResetPassword() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const credIdParam = params.get('id');
    const [showPasswordOne, setShowPasswordOne] = useState(false);
    const [showPasswordTwo, setShowPasswordTwo] = useState(false);
    const [errors, setErrors] = useState({});

    let navigate = useNavigate();

    const { onChange, onSubmit, values } = useForm(updatePswdCallback, {
        id: Number(credIdParam),
        token: params.get('token'),
        dt: params.get('dt'),
        newpassword: '',
        newpassword2: '',
    });
    function updatePswdCallback() {
        console.log('values');
        console.log(values);
        resetPswd();
    }

    const submit = (e) => {
        e.preventDefault();
        onSubmit(e);
    };

    const [resetPswd /*, { data, loading, error } */] = useMutation(RESET_PASSWORD, {
        update(proxy, { data: { resetPassword: userData } }) {
            //context.login(userData);
            setErrors([]);
            toast.success('Password updated');
            navigate('/login');
        },
        onError(err) {
            const graphQLErrors = err.graphQLErrors;
            const networkError = err.networkError;
            if (graphQLErrors && graphQLErrors.length > 0) {
                setErrors(graphQLErrors);
            }
            if (networkError) {
                setErrors([networkError]);
            }
        },
        variables: {
            fetchPolicy: 'network-only',
            id: Number(credIdParam),
            token: params.get('token'),
            dt: params.get('dt'),
            newpassword: values.newpassword,
            newpassword2: values.newpassword2,
        },
    });

    function alertMarkup() {
        if (errors && !errors.length) return null;

        return (
            <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">{`There were errors (${errors.length}) with your submission`}</h3>
                        <div className="mt-2 text-sm text-red-700">
                            <ul className="list-disc space-y-1 pl-5">
                                {errors.map(function (error, index) {
                                    return <li key={index}>{error.message}</li>;
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex">
            <div className="flex flex-1 flex-col justify-between py-12 px-4 sm:px-6 xl:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm xl:w-96">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <Link to="/">
                                <img className="h-12 w-auto" src="/CTC-Logo-2.png" alt="CheckTheChip" />
                            </Link>
                            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Reset Password</h2>
                        </div>

                        <div className="mt-8">
                            <div className="mt-6">
                                <form onSubmit={submit} className="space-y-6">
                                    <div
                                        className="space-y-1"
                                        tooltip="Password must be at least 8 characters long, contain one uppercase, one lowercase, one digit, and one special character"
                                    >
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            Password
                                        </label>
                                        <div className="mt-1 flex relative">
                                            <input
                                                id="newpassword"
                                                name="newpassword"
                                                placeholder="Min 8 incl 1 uppercase, 1 lowercase, 1 digit, 1 special char"
                                                type={showPasswordOne ? 'text' : 'password'}
                                                autoComplete="no-autocomplete"
                                                required
                                                onChange={onChange}
                                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm placeholder:mr-8"
                                            />
                                            {showPasswordOne ? (
                                                <EyeIcon
                                                    className="h-6 w-6 text-gray-500 m-2 cursor-pointer absolute right-1"
                                                    aria-hidden="true"
                                                    onClick={() => setShowPasswordOne((prevState) => !prevState)}
                                                />
                                            ) : (
                                                <EyeSlashIcon
                                                    className="h-6 w-6 text-gray-500 m-2 cursor-pointer absolute right-1"
                                                    aria-hidden="true"
                                                    onClick={() => setShowPasswordOne((prevState) => !prevState)}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    <div className="space-y-1">
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            Retype password
                                        </label>
                                        <div className="mt-1 flex relative">
                                            <input
                                                id="newpassword2"
                                                name="newpassword2"
                                                placeholder="Passwords must match"
                                                type={showPasswordTwo ? 'text' : 'password'}
                                                autoComplete="no-autocomplete"
                                                required
                                                onChange={onChange}
                                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                            {showPasswordTwo ? (
                                                <EyeIcon
                                                    className="h-6 w-6 text-gray-500 m-2 cursor-pointer absolute right-1"
                                                    aria-hidden="true"
                                                    onClick={() => setShowPasswordTwo((prevState) => !prevState)}
                                                />
                                            ) : (
                                                <EyeSlashIcon
                                                    className="h-6 w-6 text-gray-500 m-2 cursor-pointer absolute right-1"
                                                    aria-hidden="true"
                                                    onClick={() => setShowPasswordTwo((prevState) => !prevState)}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {alertMarkup()}
                                    <div>
                                        <button
                                            type="submit"
                                            className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none hover:enabled:bg-indigo-700"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <p className="mt-10 text-lg  font-medium text-gray-700">
                                Go back to{' '}
                                <Link
                                    to="/login"
                                    className="text-ctclightblue-500 font-medium hover:text-ctclightblue-400"
                                >
                                    Log In
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mx-auto max-w-7xl overflow-hidden px-6 lg:px-8">
                    <CtcLogo />
                    <p className="text-center text-xs leading-5 text-gray-500">
                        &copy; 2024 Check the Chip, Inc. All rights reserved.
                    </p>
                </div>
            </div>
            <div className="hidden flex-1 xl:block">
                <img
                    className="inset-0 h-full w-full object-cover"
                    src="/registration-dog.png"
                    alt="picture of dog with owners arm around it"
                />
            </div>
        </div>
    );
}

export default ResetPassword;
