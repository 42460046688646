import React, { useState, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Input, Form } from 'antd';
import toast from 'react-hot-toast';
import { uploadPhoto } from '../../uses/UploadPhoto';
import { AuthContext } from '../../context/AuthContext';
import './styles/UploadImageTarget.css';
import { Loader } from '../../components/Loader';

function UploadImageTarget({
    setFileUploaded,
    petid,
    type,
    image,
    setImage,
    setOpen,
    setImageSubmitted,
    updatePetProfile,
}) {
    const [file, setFile] = useState(null);
    const [caption, setCaption] = useState('');
    const [fileDropped, setFileDropped] = useState(false);
    const [loading, setLoading] = useState(false);
    const context = useContext(AuthContext);

    const onDrop = (acceptedFiles) => {
        const maxFileSize = 10 * 1024 * 1024; // 10MB
        const file = acceptedFiles[0];
        if (file.size > maxFileSize) {
            toast.error('File size too large');
            return;
        }
        setFile(file);
        setImage(URL.createObjectURL(file));
        setFileDropped(true);
    };
    const fileSelected = async (event) => {
        const maxFileSize = 10 * 1024 * 1024; // 10MB
        const file = event.target?.files[0] || event.file;

        if (file.size > maxFileSize) {
            toast.error('File size too large');
            return;
        }
        setFile(file);
        await setImage(URL.createObjectURL(file));
        setFileDropped(true);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/jpeg,image/gif,image/png',
        noClick: false,
    });

    const submit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('caption', caption);
        formData.append('petid', petid);
        formData.append('type', type);
        formData.append('mime', file.type);
        formData.append('name', file.name);
        await uploadPhoto(formData, setFileUploaded, setImageSubmitted, setLoading, file, context, setOpen);
        updatePetProfile(type);
        setLoading(false);
    };

    const reset = () => {
        setFile(null);
        setCaption('');
        setImage(null);
        setFileDropped(false);
        setOpen(false);
    };

    return (
        <div className="upload-image-container">
            <img
                onClick={reset}
                style={{
                    position: 'absolute',
                    top: 20,
                    right: 20,
                    width: 40,
                    height: 40,
                    cursor: 'pointer',
                    objectFit: 'contain',
                }}
                src="/close-icon.png"
                alt="CheckTheChipUploadIcon"
            />
            <p className="my-5 pb-5 upload-title" style={{ fontSize: '28px' }}>
                Upload an image
            </p>
            <Form
                onSubmit={submit}
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    className="space-y-1 text-center justify-center items-center cursor-pointer hover:bg-ctclightblue-300 hover:bg-opacity-10  w-full upload-dragger px-8 rounded-lg"
                    {...getRootProps()}
                >
                    {fileDropped ? (
                        <div className="flex justify-center">
                            <img className="h-32 w-32 object-cover rounded-full" src={image} alt="preview" />
                        </div>
                    ) : (
                        <svg
                            className="mx-auto h-24 w-24 text-gray-400 object-contain"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                        >
                            <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    )}
                    <div className="flex text-sm text-gray-600">
                        <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-medium text-ctcblue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-ctcblue-500 focus-within:ring-offset-2 hover:text-ctcblue-500"
                        >
                            <input
                                {...getInputProps()}
                                accept="image/jpeg,image/gif,image/png"
                                size="10485760"
                                id="file-upload"
                                onChange={fileSelected}
                                name="file-upload"
                                type="file"
                                className="sr-only"
                            />
                        </label>
                        <div className="w-full flex flex-row justify-center items-center">
                            Drag & Drop, or
                            <span className="relative cursor-pointer rounded-md bg-transparent font-medium text-ctcblue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-ctcblue-500 focus-within:ring-offset-2 hover:text-ctcblue-500 pr-1 pl-1">
                                Choose file
                            </span>
                            to upload{' '}
                        </div>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                </div>

                <Form.Item>
                    <div style={{ marginTop: 15, marginBottom: 15 }}>
                        <label className="upload-label">
                            {'Add a caption'}
                            <span className="optional">{' (optional)'}</span>
                        </label>
                    </div>
                    <Input.TextArea
                        value={caption}
                        onChange={(event) => setCaption(event.target.value)}
                        style={{ width: 313, height: 95, resize: 'none', borderColor: '#a0a0a0' }}
                        disabled={loading}
                    />
                </Form.Item>
                <Form.Item style={{ marginTop: 20, textAlign: 'center' }}>
                    <Button
                        type="primary"
                        onClick={submit}
                        block
                        disabled={loading || !fileDropped}
                        style={{
                            width: 313,
                            height: 49,
                            borderRadius: 5,
                            backgroundColor: '#09278b',
                            borderColor: '#09278b',
                            color: '#fff',
                        }}
                        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#2b2f31')}
                        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#09278b')}
                    >
                        {loading ? <Loader /> : 'Save'}
                    </Button>
                    <Button
                        onClick={reset}
                        disabled={loading}
                        style={{
                            width: 313,
                            height: 49,
                            borderRadius: 5,
                            marginTop: 10,
                            backgroundColor: '#fff',
                            borderColor: '#09278b',
                            color: '#2b2f31',
                        }}
                        onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = '#2b2f31';
                            e.currentTarget.style.color = '#fff';
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = '#fff';
                            e.currentTarget.style.color = '#2b2f31';
                        }}
                    >
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default UploadImageTarget;
