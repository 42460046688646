import { PaymentIcon } from 'react-svg-credit-card-payment-icons';
import { Indicator } from '../Indicator/Indicator';
import './styles.css';
import { useState } from 'react';
import { Modal } from 'antd';
import { Loader } from '../Loader';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

export const CreditCard = ({
    id,
    number,
    onRemove,
    isDefault,
    Expires,
    onClick,
    brand = 'generic',
    isPay = false,
    selectedCard = '',
}) => {
    const [isCardDeleting, setIsCardDeleting] = useState(false);
    const [loading, setLoading] = useState(false);

    const openModal = () => setIsCardDeleting(true);
    const closeModal = () => setIsCardDeleting(false);

    return (
        <>
            <div className="credit-card" onClick={() => onClick(id)}>
                <div className="card-info">
                    <div className="flex align-center gap-2">
                        <div className="card-number">**** **** **** {number}</div>
                        <PaymentIcon type={brand} format="flatRounded" width={40} />
                    </div>
                    <div className="card-details">
                        <div>Expires on {Expires}</div>
                        <div className="dot">•</div>
                        {isDefault ? (
                            <div className="flex align-center gap-3">
                                Default
                                <Indicator />
                            </div>
                        ) : (
                            <div className="flex align-center gap-3">Backup</div>
                        )}
                    </div>
                </div>
                {!isPay && (
                    <div className="card-actions">
                        <button
                            type="button"
                            className="inline-flex justify-center items-center gap-x-2 rounded-md border border-ctcdarkblue py-2 px-4 text-sm font-medium text-ctcdarkblue shadow-sm hover:bg-ctcdarkblue hover:enabled:bg-ctcdarkblue hover:text-white focus:ring-ctcblue-500 focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ctcdarkblue w-full"
                            onClick={(e) => {
                                e.stopPropagation();
                                openModal();
                            }}
                        >
                            Remove card
                        </button>
                    </div>
                )}

                {selectedCard === id && <CheckCircleIcon className="h-8 w-8 text-green-500" />}
            </div>

            <Modal
                open={isCardDeleting}
                centered
                title={<h2>Delete Payment Method</h2>}
                onCancel={closeModal}
                width={600}
                footer={null}
                styles={{
                    header: {
                        paddingBottom: 12,
                    },
                    content: {
                        padding: 24,
                    },
                }}
            >
                <div className="flex flex-col gap-6 ">
                    <div className="flex flex-col justify-center align-center gap-4">
                        <h2 className="text-lg font-medium text-gray-700">
                            Are you sure you want to delete this payment method?
                        </h2>
                        <div className="credit-card" style={{ margin: 0 }}>
                            <div className="card-info">
                                <div className="flex align-center gap-2">
                                    <div className="card-number">**** **** **** {number}</div>
                                    <PaymentIcon type={brand} format="flatRounded" width={40} />
                                </div>
                                <div className="card-details">
                                    <div>Expires on {Expires}</div>
                                    <div className="dot">•</div>
                                    {isDefault ? (
                                        <div className="flex align-center gap-3">
                                            Default
                                            <Indicator />
                                        </div>
                                    ) : (
                                        <div className="flex align-center gap-3">Backup</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center items-center gap-10">
                        <button
                            onClick={closeModal}
                            className="flex justify-center rounded-md border border-ctcdarkblue mt-5 py-2 px-6 text-sm font-medium text-ctcdarkblue shadow-sm hover:bg-ctcdarkblue hover:enabled:bg-ctcdarkblue hover:text-white focus-visible:outline-ctcdarkblue"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={async () => {
                                setLoading(true);
                                await onRemove(id);
                                setLoading(false);
                                closeModal();
                            }}
                            className="flex justify-center rounded-md border border-transparent bg-red-600 mt-5 py-2 px-6 text-sm font-medium text-white shadow-sm hover:bg-red-800 focus:outline-none hover:enabled:bg-red-800"
                        >
                            {loading ? <Loader /> : 'Delete'}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
