import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { environment as environmentLocal } from './config/config';
import { environment as environmentCi } from './config/environment.ci';

const environment = window.location.hostname !== 'localhost' ? environmentCi : environmentLocal;

const httpLink = createHttpLink({
    uri: environment.apiUrl + environment.GraphQlURL,
});

const authLink = setContext((_, { headers }) => {
    const tokenValue = 'Bearer ' + localStorage.getItem('accesstoken');
    return {
        headers: {
            ...headers,
            authorization: tokenValue || '',
            'Access-Control-Allow-Origin': '*',
            Host: window.location.origin,
        },
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    fetchOptions: {
        mode: 'no-cors',
    },
});

export default client;
