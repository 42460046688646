import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import { Toaster } from 'react-hot-toast';

import Overview from './pages/Overview';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotUsername from './pages/ForgotUsername';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Dashboard from './pages/Dashboard';

const App = () => {
    const { user } = useContext(AuthContext);
    const isAuthenticated = user && user.username;
    const isAdmin = user && user.is_admin;

    return (
        <>
            <Toaster />
            <Routes>
                <Route path="/login" element={<Login />} />

                <Route path="/ctc/*" element={isAuthenticated ? <Overview /> : <Login />} />
                <Route
                    path="/admin"
                    element={isAuthenticated ? isAdmin ? <Dashboard /> : <Overview /> : <Login isAdminRoute />}
                />
                <Route path="/register" element={<Register />} />
                <Route path="/forgotusername" element={<ForgotUsername />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/*" element={isAuthenticated ? <Overview /> : <Login />} />
            </Routes>
        </>
    );
};

export default App;
