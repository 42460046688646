import { useNavigate } from "react-router-dom";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

function OwnersCard({ user }) {
  const navigate = useNavigate();
  function updateOwnersProfile() {
    navigate("edit");
  }

  function getAddress(location) {
    if (!location.street1) return null;
    return (
      <>
        <dt className="font-display text-sm font-semibold mt-4">Address</dt>
        <dd className="font-display mt-1 text-sm text-gray-900">
          {location.street1}
        </dd>
        {location.street2 && location.street2 !== "TESTTESTTEST" ? (
          <dd className="mt-1 text-sm text-gray-900">{location.street2}</dd>
        ) : null}
        <dd className="font-display mt-1 text-sm text-gray-900">
          {location.city}, {location.state} {location.zip}
        </dd>
      </>
    );
  }
  function getPhone(phonenumber, phonetype) {
    let phoneLabel = "Phone";
    let phonetypeLabel = "";
    if (phonetype === "OFFICE") {
      phonetypeLabel = "(Office)";
    }
    if (phonetype === "HOME") {
      phonetypeLabel = "(Home)";
    }
    if (phonetype === "CELL") {
      phonetypeLabel = "(Cell)";
    }

    return (
      <>
        <dt className="font-display text-sm font-semibold mt-4">
          {phoneLabel}
        </dt>
        <dd className="font-display mt-1 text-sm text-gray-900">
          {phonenumber + " " + phonetypeLabel}
        </dd>
      </>
    );
  }
  function getEmail(email, emailtype, emailLabelStart) {
    console.log("emailtype");
    console.log(emailtype);
    console.log(user);
    let emailLabel = email;
    if (emailtype === "PERSONAL") {
      emailLabel = `${email} (Personal)`;
    }
    if (emailtype === "WORK") {
      emailLabel = `${email} (Office/Work)`;
    }
    if (emailtype === "OTHER") {
      emailLabel = `${email} (Other)`;
    }
    if (emailtype === "SCHOOL") {
      emailLabel = `${email} (School)`;
    }
    return (
      <>
        <dt className="font-display text-sm font-semibold mt-4">
          {emailLabelStart}
        </dt>
        <dd className="font-display mt-1 text-sm text-gray-900">
          {emailLabel}
        </dd>
      </>
    );
  }

  return (
    <div className="sm:col-span-1 sm:row-span-2">
      <dt className="flex text-sm font-medium text-gray-500">
        <span className="flex-grow"></span>
        <span className="mr-4 flex-shrink-0">
          <button
            onClick={updateOwnersProfile}
            type="button"
            className="inline-flex justify-center items-center gap-x-2 rounded-md border border-ctcdarkblue py-2 px-4 text-sm font-medium text-ctcdarkblue shadow-sm hover:bg-ctcblack-100 hover:text-white focus:ring-ctcblue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ctcdarkblue"
          >
            Edit Profile
            <PencilSquareIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
          </button>
        </span>
      </dt>
      <dd className="font-display mt-1 text-3xl text-black">
        {user.firstname} {user.lastname}
      </dd>
      <span className="font-display flex text-xs font-xs text-gray-500">
        Primary Owner
      </span>
      {user.location ? getAddress(user.location) : null}
      {user.phonenumber
        ? getPhone(
            user.phonenumber,
            user.phonetype ? user.phonetype : "",
            "Primary phone number",
          )
        : null}
      {user.phonenumber2
        ? getPhone(
            user.phonenumber2,
            user.phonetype2 ? user.phonetype2 : "",
            "Second phone number",
          )
        : null}
      {user.phonenumber3
        ? getPhone(
            user.phonenumber3,
            user.phonetype3 ? user.phonetype3 : "",
            "Third phone number",
          )
        : null}
      {user.email
        ? getEmail(
            user.email,
            user.emailtype ? user.emailtype : "",
            "Primary Email",
          )
        : null}
      {user.email2
        ? getEmail(
            user.email2,
            user.emailtype2 ? user.emailtype2 : "",
            "Secondary Email",
          )
        : null}
      {user.email3
        ? getEmail(
            user.email3,
            user.emailtype3 ? user.emailtype3 : "",
            "Third Email",
          )
        : null}
    </div>
  );
}

export default OwnersCard;
