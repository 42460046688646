import React, { useState } from 'react';
import { Modal, Button, Form, Input, Select, message } from 'antd';
import './CreateUserForm.css';
import axiosInstance, { getApiService } from '../../../utility/api';

const { Option } = Select;

const CreateUserForm = ({ fetchData }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    const handleCreate = async (values) => {
        setLoading(true);
        try {
            const response = await axiosInstance.post(`/admin/users`, { ...values });
            if (response.data.success) {
                message.success('User created successfully');
                setVisible(false);
                form.resetFields();
                await fetchData({});
            } else {
                message.error('Failed to create user');
            }
        } catch (error) {
            message.error('An error occurred while creating the user');
            console.error('createAdmin error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button className="wrap-btn my-5" onClick={showModal}>
                    + Create Admin
                </Button>
            </div>
            <Modal
                title="Create New Admin"
                visible={visible}
                onCancel={handleCancel}
                footer={null}
                className="modal-form"
            >
                <Form form={form} layout="vertical" onFinish={handleCreate} className="modal-form">
                    <Form.Item
                        name="firstname"
                        label="First Name"
                        rules={[{ required: true, message: 'Please input the first name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="lastname"
                        label="Last Name"
                        rules={[{ required: true, message: 'Please input the last name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ required: true, message: 'Please input the email!', type: 'email' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="username"
                        label="Username"
                        rules={[{ required: true, message: 'Please input the username!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: true, message: 'Please input the password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="phonenumber"
                        label="Phone Number"
                        rules={[
                            {
                                pattern: /^\+?(\d.*){3,}$/,
                                message: 'Please input a valid phone number!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="role" label="Role" rules={[{ required: true, message: 'Please select a role!' }]}>
                        <Select placeholder="Select a role">
                            <Option disabled={true} value="manager">
                                Manager
                            </Option>
                            <Option value="admin">Admin</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button className="wrap-btn" htmlType="submit" loading={loading} style={{ float: 'right' }}>
                            Create
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default CreateUserForm;
