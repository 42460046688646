import React from 'react';
import Heading from '../components/Heading';
import { Tabs } from 'antd';
import ScanReport from "./Dashboard/ScanReport";
import Admins from "./Dashboard/Admins"; // Import the Admins component

export default function Dashboard({ userData, setUserData }) {
    return (
        <div>
            <div className="py-2 pl-8 pr-3">
                <Heading heading="Dashboard" />
            </div>
            <Tabs defaultActiveKey="1" className="py-2 pl-8 pr-3">
                <Tabs.TabPane tab="Scan Report" key="1">
                    <ScanReport />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Admins" key="2">
                    <Admins />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
}
