import { gql } from '@apollo/client';

export const GET_PET = gql`
    query getPet($id: Int) {
        user {
            id
            pets(id: $id) {
                id
                name
                pettype
                microchipnumber
                lost
                lostdescription
                lostlocation
                lostdatetime
                lostother
                registrationstatus
                birthdate
                birthdateisapproximate
                sex
                lifestage
                abouttext
                findermessage
                createdAt
                imageidone
                imageidtwo
                imageurlone
                imageurltwo
                imagetitleone
                imagetitletwo
                origin {
                    createdAt
                    kiosk {
                        name
                        kiosklocation {
                            type
                            city
                        }
                    }
                }
            }
        }
    }
`;
