import ProfilePic from '../../components/PetProfile/ProfilePic';
import React from 'react';

function PetProfileAvatar({ pet, updatedPatImage, image }) {
    if (pet.imageurlone || image) {
        return <ProfilePic petdata={pet} petid={pet.id} type="petprimary" setImage={updatedPatImage} image={image} />;
    }
    return (
        <div className="sm:col-span-1 sm:row-span-1">
            <div className="mx-auto">
                <img
                    className="mx-auto h-36 w-36 object-contain rounded-full"
                    src={pet.pettype === 'DOG' ? '/dog-placeholder.png' : '/cat-placeholder.png'}
                    alt=""
                />
            </div>
        </div>
    );
}
export default PetProfileAvatar;
