import axios from 'axios';
import { environment as environmentLocal } from '../../config/config';
import { environment as environmentCi } from '../../config/environment.ci';

const environment = window.location.hostname !== 'localhost' ? environmentCi : environmentLocal;
const apiUrl = environment.apiUrl + environment.ApiPostfix + '/';

const axiosInstance = axios.create({
    baseURL: apiUrl,
    timeout: 30000,
});

axiosInstance.interceptors.request.use(
    (config) => {
        config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accesstoken');
        config.headers['Access-Control-Allow-Origin'] = '*';
        config.headers['Host'] = window.location.origin;

        return config;
    },
    (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error?.response?.status === 403) {
            localStorage.clear();
            window.location.reload();
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;
