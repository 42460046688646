import { AuthContext } from '../context/AuthContext';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import Heading from '../components/Heading';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import PetsCard from '../components/PetsCard';
import { ArrowLeftIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import LostPetForm from '../components/LostPetForm';
import ReactModal from 'react-modal';
import LostReport from './PetProfile/LostReport';
import AboutPet from './PetProfile/AboutPet';
import PetProfileAvatar from './PetProfile/PetProfileAvatar';
import PhotoUploadModal from '../components/PhotoUploadModal';
import SectionPhotos from './PetProfile/SectionPhotos';
import axiosInstance, { getApiService } from '../utility/api';
import { photoTypes } from './../constants/profile';
import * as petApi from '../utility/api/pets';
import { Button, Modal } from 'antd';
import { Tooltip } from 'react-tooltip';
import { useCreditCards } from '../utility/hooks';
import { StripeCard } from './PaymentMethods/StripeCard';
import { CreditCard } from '../components/CreditCard/CreditCard';
import { Loader } from '../components/Loader';

const customStyles = {
    content: {
        marginTop: '5%',
        marginLeft: '30%',
        width: '40%',
        position: 'absolute',
    },
};

ReactModal.setAppElement('#root');

const UPDATE_PET_AS_LOST = gql`
    mutation updatePet($input: PetInput!) {
        updatePet(input: $input) {
            id
            name
            lost
            lostdatetime
            lostlocation
            lostdescription
            lostother
        }
    }
`;

function PetProfile() {
    const context = useContext(AuthContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [lostPetModalIsOpen, setIsOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [loadingImageOne, setLoadingImageOne] = useState(false);
    const [loadingImageTwo, setLoadingImageTwo] = useState(false);

    const [image, setImage] = useState(null); // from file input converted to in memory for preview
    const [imagetwo, setImagetwo] = useState(null); // from file input converted to in memory for preview
    const [openType, setOpenType] = useState(''); // from file input converted to in memory for preview
    const [loading, setLoading] = useState(true);
    const [pet, setPet] = useState();

    const openLostPetModal = (e) => {
        setIsOpen(true);
    };

    function afterOpenLostPetModal() {
        // subtitle.style.color = "#f00";
    }

    function closeLostPetModal() {
        setIsOpen(false);
    }

    const [updatePetAsLost] = useMutation(UPDATE_PET_AS_LOST);

    const onCompletedLostPet = (data) => {
        toast.success('Lost pet form submitted successfully');
        setIsOpen(false);
    };

    const onErrorLostPet = (error) => {
        if (error.message === 'GraphQL error: User not found') {
            toast.error(error.message);
        } else {
            toast.error('Error submitting lost pet form');
        }
        if (error.message === 'Unauthenticated Context') {
            context.logout();
        }
    };

    const onErrorGetUserPet = (error) => {
        console.log(error);
        if (error.message === 'GraphQL error: User not found') {
            toast.error(error.message);
        } else {
            toast.error('Error getting pet data');
        }
        if (error.message === 'Unauthenticated Context') {
            context.logout();
        }
    };

    // const { loading, error, data, refetch } = useQuery(GET_PET, {
    //   variables: { id: id ? Number(id) : undefined },
    //   fetchPolicy: "network-only",
    //   onError: onErrorGetUserPet,
    // });

    const fetchData = async () => {
        const res = await petApi.getPet(id);

        if (res.pets) {
            return setPet(res.pets[0]);
        }

        onErrorGetUserPet(res);
    };

    useState(() => {
        setLoading(true);
        fetchData().finally(() => setLoading(false));
    }, [id]);

    const {
        creditCards,
        loading: paymentLoading,
        fetchCards,
        payWithMethodId,
        createAndPay,
        setLoading: setPaymentLoading,
        errorMessage,
        setErrorMessage,
        success,
    } = useCreditCards();
    const [isOpenedPayment, setIsOPenedPayment] = useState(false);
    const [isAddingNewMethod, setIsAddingNewMethod] = useState(creditCards.length === 0 ? true : false);
    const [selectedCard, setSelectedCard] = useState('');

    const selectCardForPay = (id) => setSelectedCard(id);

    const getCards = async () => {
        await fetchCards();
        setSelectedCard(creditCards?.find((card) => card.isDefault)?.id || '');
    };

    const openPayment = async () => {
        setIsAddingNewMethod(creditCards.length === 0 ? true : false);
        setSelectedCard(creditCards?.find((card) => card.isDefault)?.id || '');
        setIsOPenedPayment(true);
    };
    const close = () => {
        setIsOPenedPayment(false);
        setIsAddingNewMethod(creditCards.length === 0 ? true : false);
        setIsAddingNewMethod(false);
        setSelectedCard(creditCards?.find((card) => card.isDefault)?.id || '');
    };

    useEffect(() => {
        getCards();
    }, []);

    useEffect(() => {
        setSelectedCard(creditCards?.find((card) => card.isDefault)?.id || '');
    }, [creditCards]);

    const updatedPatImage = async (type) => {
        try {
            if (type === photoTypes.petprimary) {
                setLoadingImageOne(true);
            } else {
                setLoadingImageTwo(true);
            }

            setLoading(true);
            await fetchData();
        } catch (error) {
            console.error('Error updating image:', error);
        } finally {
            if (type === photoTypes.petprimary) {
                setLoadingImageOne(false);
            } else {
                setLoadingImageTwo(false);
            }

            setLoading(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    function updatePetsProfile() {
        navigate('edit');
    }

    const handleRemovePhoto = async (type) => {
        try {
            setLoading(true);
            if (type === photoTypes.petprimary) {
                setLoadingImageOne(true);
            } else {
                setLoadingImageTwo(true);
            }

            const imageId =
                type === photoTypes.petprimary ? pet.petprimaryimages[0]?.id : pet.petsecondaryimages[0]?.id;
            if (!imageId) {
                setImage(null);
                setImagetwo(null);
                setLoadingImageOne(false);
                setLoadingImageTwo(false);
                setLoading(false);
                return;
            }
            await axiosInstance.delete(`/pets/${pet.id}/images/${imageId}`);

            if (type === photoTypes.petprimary) {
                setImage(null);
            } else {
                setImagetwo(null);
            }

            await fetchData();
        } catch (error) {
            console.error('Error removing photo:', error);
        } finally {
            if (type === photoTypes.petprimary) {
                setLoadingImageOne(false);
            } else {
                setLoadingImageTwo(false);
            }
            setLoading(false);
        }
    };

    function detailsMarkup() {
        return (
            <div className="overflow-hidden bg-white shadow sm:rounded-lg border-solid border-2 border-gray">
                <div className="px-4 py-5 sm:px-6">
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                        <PetProfileAvatar pet={pet} updatedPatImage={updatedPatImage} image={image} />
                        <LostReport pet={pet} openLostPetModal={openLostPetModal} />
                        {pet?.billing?.status !== 'paid' && (
                            <div className="flex flex-col sm:items-end items-center">
                                <button
                                    onClick={openPayment}
                                    className="w-fit rounded-md border border-transparent bg-indigo-600 py-2 px-6 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none hover:enabled:bg-indigo-700"
                                >
                                    Pay one time subscription
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-[90%] mx-auto border-b border-gray"></div>
                <div className=" px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-3 gap-x-2 gap-y-2 sm:grid-cols-3 lg:grid-cols-3">
                        <div className="sm:col-span-1 sm:row-span-1">
                            <span className="font-display flex-grow ml-10 text-xl font-large">Pet Info</span>
                        </div>
                        <div className="sm:col-span-2 sm:row-span-2">
                            <AboutPet abouttext={pet.abouttext} />
                            <PetsCard pet={pet} />
                            <SectionPhotos
                                imageurlone={image || pet.imageurlone}
                                imagetitletwo={imagetwo || pet.imageurltwo}
                                setOpen={setOpen}
                                setOpenType={setOpenType}
                                handleRemovePhoto={handleRemovePhoto}
                                loadingImageOne={loadingImageOne}
                                loadingImageTwo={loadingImageTwo}
                            />
                        </div>
                    </dl>
                </div>
            </div>
        );
    }

    const onSubmitChanges = async (data) => {
        console.log('WHAT IS THIS: ', data);

        const res = await petApi.updatePet(id, {
            name: pet.name,
            lost: true,
            lostdescription: data.lostdescription,
            lostlocation: data.lostlocation,
            lostdatetime: data.lostdatetime,
            lostother: data.lostother,
        });

        if (res.pet) {
            setPet(res.pet);
            return onCompletedLostPet();
        }

        onErrorLostPet(res);

        // updatePetAsLost({
        //     variables: {
        //         input: {
        //             id: Number(id),
        //             name: pet.name,
        //             lost: true,
        //             lostdescription: data.lostdescription,
        //             lostlocation: data.lostlocation,
        //             lostdatetime: data.lostdatetime,
        //             lostother: data.lostother,
        //         },
        //     },
        //     onCompleted: onCompletedLostPet,
        //     onError: onErrorLostPet,
        // });
    };

    return (
        <>
            {pet && (
                <PhotoUploadModal
                    open={open}
                    setOpen={setOpen}
                    petid={pet.id}
                    type={openType === photoTypes.petprimary ? photoTypes.petprimary : photoTypes.petsecondary}
                    image={openType === photoTypes.petprimary ? image : imagetwo}
                    setImage={openType === photoTypes.petprimary ? setImage : setImagetwo}
                    url={openType === photoTypes.petprimary ? pet.imageurlone : pet.imageurltwo}
                    updatePetProfile={updatedPatImage}
                />
            )}
            <div className="py-2 pl-8 pr-3">
                <div className="flex items-center gap-2">
                    <Heading heading="Pet Profile" />
                    <Button
                        className="text-ctclightblue-500 hover:text-ctclightblue-900"
                        data-tooltip-id="edit"
                        type="text"
                        shape="circle"
                        icon={<PencilSquareIcon className="h-6 w-6" aria-hidden="true" />}
                        onClick={updatePetsProfile}
                    />
                    <Tooltip
                        id="edit"
                        content="Edit"
                        style={{ backgroundColor: '#37a5ff', zIndex: 100 }}
                        place="bottom"
                    />
                </div>

                {detailsMarkup()}

                <ReactModal
                    isOpen={lostPetModalIsOpen}
                    onAfterOpen={afterOpenLostPetModal}
                    onRequestClose={closeLostPetModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <LostPetForm setIsOpen={setIsOpen} onSubmitChanges={onSubmitChanges}></LostPetForm>
                </ReactModal>

                {isOpenedPayment && (
                    <Modal
                        open={isOpenedPayment}
                        centered
                        title={
                            isAddingNewMethod ? (
                                creditCards.length && (
                                    <Button
                                        className="text-ctclightblue-500 hover:text-ctclightblue-900"
                                        data-tooltip-id="edit"
                                        type="text"
                                        shape="circle"
                                        icon={<ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />}
                                        onClick={() => setIsAddingNewMethod(false)}
                                    />
                                )
                            ) : (
                                <h2 className="text-lg font-medium text-gray-700">Pay</h2>
                            )
                        }
                        onCancel={close}
                        width={600}
                        footer={null}
                        styles={{
                            header: {
                                paddingBottom: 12,
                            },
                            content: {
                                padding: 24,
                            },
                        }}
                    >
                        <div className="flex flex-col gap-3">
                            <h2 className="text-lg font-medium text-gray-700 text-center">
                                Select a payment method or use other method to pay for {pet?.name || 'your pet'}.
                            </h2>

                            {isAddingNewMethod ? (
                                <StripeCard
                                    close={close}
                                    submit={createAndPay}
                                    loading={paymentLoading}
                                    setLoading={setPaymentLoading}
                                    errorMessage={errorMessage}
                                    setErrorMessage={setErrorMessage}
                                    text={'Pay $49'}
                                    type="pay"
                                    petId={pet?.id}
                                    success={success}
                                    callback={fetchData}
                                />
                            ) : (
                                <div>
                                    {creditCards?.length ? (
                                        creditCards?.map((card) => (
                                            <CreditCard
                                                key={card.id}
                                                id={card.id}
                                                isDefault={card.isDefault}
                                                number={card.number}
                                                Expires={`${card?.expMonth}/${card?.expYear.slice(-2)}`}
                                                onClick={selectCardForPay}
                                                brand={card.brand}
                                                isPay
                                                selectedCard={selectedCard}
                                            />
                                        ))
                                    ) : (
                                        <div className="flex flex-col justify-center align-center gap-3 mb-5 mt-5">
                                            <img src="/pets-icon.png" />
                                            <h2 className="text-lg font-medium text-gray-700">
                                                You dont have any payment methods
                                            </h2>
                                        </div>
                                    )}

                                    <div onClick={() => setIsAddingNewMethod(true)} className="flex justify-end">
                                        <button className="text-b py-2 px-8 text-sm font-medium text-gray-700 focus:outline-none hover:enabled:text-ctclightblue-500 hover:enabled:bg-white">
                                            Use other payment method
                                        </button>
                                    </div>
                                </div>
                            )}

                            {!isAddingNewMethod && (
                                <button
                                    onClick={async () => {
                                        await payWithMethodId(selectedCard, pet.id);
                                        await fetchData();
                                        close();
                                    }}
                                    disabled={!selectedCard}
                                    className="w-full inline-flex justify-center items-center rounded-md border border-transparent bg-indigo-600 py-2 px-8 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none
                            hover:disabled:bg-gray-300 
                            hover:enabled:bg-indigo-700"
                                >
                                    {!paymentLoading ? 'Pay $49' : <Loader />}
                                </button>
                            )}
                        </div>
                    </Modal>
                )}
            </div>
        </>
    );
}

export default PetProfile;
