import { CheckIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";

const CompleteUpload = ({ onClick }) => (
    <div className="p-6">
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
            >
                Lookin’ beautiful!
            </Dialog.Title>
            <div className="mt-6"></div>
        </div>
        <div className="inline-flex justify-center w-full mt-5 sm:mt-6">
            <button
                type="button"
                className="inline-flex justify-center rounded-md border border-transparent bg-ctcblue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-ctcblue-700 focus:outline-none focus:ring-2 focus:ring-ctcblue-500 focus:ring-offset-2 sm:text-sm"
                onClick={onClick}
            >
                Done
            </button>
        </div>
    </div>
);

export default CompleteUpload;
