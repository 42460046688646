import PetPhoto from "./PetPhoto";
import React from "react";
import {photoTypes} from "../../constants/profile";

function SectionPhotos({imageurlone, imagetitletwo, setOpen, setOpenType, handleRemovePhoto, isEditPage=false , loadingImageOne = false, loadingImageTwo = false }) {
    return (
        <div className="sm:col-span-1 text-sm font-medium text-black-500 pt-6">
            <dt className="font-display flex items-center">Photos</dt>
            <dt className="font-display flex items-center mb-4">
                We recommend adding two varied images that best<br />
                showcase your pet for easy identification
            </dt>
            <div className="flex">
                <div className="mr-5">
                    <PetPhoto isEditPage={isEditPage} setOpen={setOpen} imageUrl={imageurlone} type={photoTypes.petprimary} setOpenType={setOpenType} handleRemovePhoto={handleRemovePhoto} loading={loadingImageOne}/>
                </div>
                <PetPhoto isEditPage={isEditPage} setOpen={setOpen} imageUrl={imagetitletwo} type={photoTypes.petsecondary} setOpenType={setOpenType} handleRemovePhoto={handleRemovePhoto} loading={loadingImageTwo}/>
            </div>
        </div>
    );
}

export default SectionPhotos;
