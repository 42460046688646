import { useEffect, useState } from 'react';

export const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
        month: 'numeric',
        day: '2-digit',
        year: 'numeric',
    });
};

export function useStorageWatcher(key = 'accesstoken') {
    const [val, setVal] = useState(localStorage.get(key));

    useEffect(() => {
        const handler = () => val !== localStorage.get(key) && setVal(localStorage.get(key));
        window.addEventListener('storage', handler);
        return () => window.removeEventListener('storage', handler);
    }, []);

    useEffect(() => {
        setVal(localStorage.get(key));
    }, [key]);

    return val;
}
