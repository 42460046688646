import { InformationCircleIcon } from '@heroicons/react/24/outline';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { Tooltip } from 'react-tooltip';
import { prefix } from './Prefix';

function PetsCard({ pet }) {
    return (
        <div className="sm:col-span-1 sm:row-span-2">
            {pet.birthdate === null || pet.birthdate === undefined || pet.birthdate === '' ? null : (
                <>
                    <dt className="font-display text-sm font-medium text-black mt-6">
                        <span className="flex-grow">Birthdate</span>
                    </dt>
                    <div className="flex w-full items-center">
                        <dd className="mt-1 text-sm  text-gray-900">
                            {pet.birthdate ? new Date(pet.birthdate).toDateString() : ''}
                            <span className="flex-grow">{pet.birthdateisapproximate ? ' (Approximate)' : ''}</span>
                        </dd>
                    </div>
                    <dd className="mt-1 text-sm  text-gray-900">
                        <span className="flex-grow">
                            {pet.birthdate ? formatDistanceToNow(new Date(pet.birthdate)) : ''}
                        </span>
                    </dd>
                </>
            )}
            {pet.lifestage === null || pet.lifestage === undefined || pet.lifestage === '' ? null : (
                <>
                    <dt className="font-display flex text-sm font-large text-black mt-6">
                        <span className="flex-grow">Pets Lifestage</span>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">{pet && pet.lifestage}</dd>
                </>
            )}
            {pet.sex === null || pet.sex === undefined || pet.sex === '' ? null : (
                <>
                    <dt className="font-display flex text-sm font-medium text-black mt-6">
                        <span className="flex-grow">Gender</span>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">{pet.sex}</dd>
                </>
            )}
            {/* { (pet.registrationdate === null || pet.registrationdate === undefined || pet.registrationdate === '') ? ( null ) : (
                <>
                    <dt className="font-display flex text-sm font-medium text-black mt-6">
                        <span className="flex-grow">Registration Date</span>
                    </dt>
                    <dd className="mt-1 text-sm  text-gray-900">{pet.registrationdate ? new Date(pet.registrationdate).toDateString(): ''} </dd>
                </>)
            } */}
            {
                <>
                    <dt className="font-display flex text-sm font-medium text-black mt-6">
                        <span className="flex mr-2">Chip Info</span>
                        <InformationCircleIcon
                            data-tooltip-id="chip-info"
                            data-tooltip-content="Microchip data is non-editable for security and accuracy, ensuring reliable identification for lost pet reunions, if incorrect contact us at support@checkthechip.com"
                            className="-mr-0.5 h-5 w-5"
                            aria-hidden="true"
                        />
                        <Tooltip
                            id="chip-info"
                            place="right"
                            border="1px solid #36A5FF"
                            style={{
                                backgroundColor: 'rgb(255, 255, 255)',
                                width: '50%',
                                color: '#3092e3',
                            }}
                        />
                    </dt>
                    <dd className="font-display mt-1 text-sm text-gray-900">
                        Registered
                        {prefix(' at', pet?.origin?.kiosk?.kiosklocation?.city)}
                        {prefix(' on', pet?.origin?.createdAt)}
                    </dd>
                    <dd className="font-display mt-1 text-sm text-gray-900">
                        Microchip Number: {pet && pet.microchipnumber}
                    </dd>
                </>
            }
        </div>
    );
}

export default PetsCard;
