import axiosInstance, { getApiService } from '.';

export const getPaymentMethods = async () => {
    try {
        const res = await axiosInstance.get('/payments').then((res) => res.data);
        return res;
    } catch (error) {
        return error?.response?.data?.message || error?.message;
    }
};

export const createPaymentMethod = async (paymentMethodStripeId) => {
    try {
        const res = await axiosInstance
            .post('/stripe/payment-method', {
                paymentMethodStripeId,
            })
            .then((res) => res.data);
        return res;
    } catch (error) {
        return error?.response?.data?.message || error?.message;
    }
};

export const deletePaymentMethod = async (paymentMethodStripeId) => {
    try {
        const res = await axiosInstance.delete(`/payments/${paymentMethodStripeId}`).then((res) => res.data);
        return res;
    } catch (error) {
        return error?.response?.data?.message || error?.message;
    }
};

export const updatePaymentMethod = async (paymentMethodStripeId) => {
    try {
        const res = await axiosInstance.put(`/payments/default/${paymentMethodStripeId}`).then((res) => res.data);
        return res;
    } catch (error) {
        return error?.response?.data?.message || error?.message;
    }
};

export const createTransaction = async (paymentMethodStripeId, petId) => {
    try {
        const res = await axiosInstance
            .post(`stripe/payment`, {
                paymentMethodId: paymentMethodStripeId,
                petid: petId,
            })
            .then((res) => res.data);
        return res;
    } catch (error) {
        return error?.response?.data?.message || error?.message;
    }
};
