import React from "react";

function AboutPet({abouttext}) {
    if (abouttext) {
        return (
            <div className="sm:col-span-1">
                <dt className="font-display text-sm font-medium text-black">About</dt>
                <dd className="mt-1 text-sm text-gray-900">{abouttext}</dd>
            </div>
        );
    }
    return null;
}

export default AboutPet;
