import { Link } from 'react-router-dom';

export const CtcLogo = () => {
    return (
        <div className="flex justify-center space-x-10">
            <Link to="/" className="text-gray-400 hover:text-gray-500">
                <img className="w-[30vh]" src="/logo-wide.jpg" alt="Check The Chip" />
            </Link>
        </div>
    );
};
