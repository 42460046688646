import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import MenuButton from './MenuButton';
import HelpMenu from './HelpMenu';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

function Toolbar({ setSidebarOpen }) {
    const context = useContext(AuthContext);
    const navigate = useNavigate();

    return (
        <div className="z-10 flex h-42 flex-shrink-0">
            {context.user && context.user.username ? <MenuButton setSidebarOpen={setSidebarOpen}></MenuButton> : null}
            <div className="flex flex-1 justify-end px-4 mt-5 align-center">
                <div className="ml-4 flex md:ml-6 ">
                    {/* <div className="lg:border-t lg:border-b lg:border-yellow-100">
            {context.user && context.user.username ? stepMarkup() : null}
          </div> */}
                    {context.user && context.user.username ? <HelpMenu></HelpMenu> : null}
                </div>
                {context.user && !context.user.is_admin ? (
                    <div className="ml-4 flex md:ml-6">
                        <Button
                            data-tooltip-id="my-tooltip"
                            type="text"
                            shape="circle"
                            icon={<CreditCardIcon className="h-5 w-5" />}
                            onClick={() => navigate('payments/billing')}
                        />
                        <Tooltip id="my-tooltip" content="Payments" style={{ backgroundColor: '#37a5ff' }} />
                    </div>
                ) : null}

                <div className="ml-4 flex md:ml-6">
                    {/* Profile dropdown */}
                    {/* {context.user && context.user.username ? <ProfileDropdown></ProfileDropdown> : null} */}
                    {context.user && context.user.username ? (
                        <div role="button" onClick={context.logout} className="block px-4 text-sm text-ctcblack-600">
                            Log Out
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default Toolbar;
