import React, { useState, useEffect } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'react-tooltip';
import PhotoUploadModal from '../PhotoUploadModal';
import ProfilePic from './ProfilePic';
import ChipInfo from './ChipInfo';
import SectionPhotos from '../../pages/PetProfile/SectionPhotos';
import { photoTypes } from '../../constants/profile';

function PetProfileEdit({ petdata, onSubmitChanges }) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [imagetwo, setImagetwo] = useState(null);
    const [openType, setOpenType] = useState('');

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: petdata });

    useEffect(() => {
        if (petdata.birthdate) {
            setValue('birthdate', new Date(petdata.birthdate).toISOString().substring(0, 10));
        }
    }, [petdata, setValue]);

    const onSubmit = (data, event) => {
        onSubmitChanges(data);
    };

    const onError = (errors, e) => {
        console.log(errors, e);
        console.log({ errors }, { e });
        toast.error('Form is invalid, please check your entries');
    };
    const inputClass =
        'mt-1 block w-80 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm';
    const errorInputClass =
        'mt-1 block w-80 rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm outline-none';

    return (
        <>
            <div>
                {petdata && (
                    <PhotoUploadModal
                        open={open}
                        setOpen={setOpen}
                        petid={petdata.id}
                        type={openType === photoTypes.petprimary ? photoTypes.petprimary : photoTypes.petsecondary}
                        image={openType === photoTypes.petprimary ? image : imagetwo}
                        setImage={openType === photoTypes.petprimary ? setImage : setImagetwo}
                        url={openType === photoTypes.petprimary ? petdata.imageurlone : petdata.imageurltwo}
                    />
                )}

                <div className="md:grid md:grid-cols-2 md:gap-6">
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <form onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                                <button
                                    type="submit"
                                    className="inline-flex justify-center rounded-md border border-ctcdarkblue mr-4 py-2 px-4 text-sm font-medium text-ctcdarkblue shadow-sm hover:bg-ctcblack-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-ctcdarkblue focus:ring-offset-2"
                                >
                                    Save Changes
                                </button>
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        navigate(-1);
                                    }}
                                    className="rounded-md border border-ctcdarkblue bg-white mr-2 py-2 px-4 text-sm font-medium text-ctcdarkblue shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-ctcdarkblue focus:ring-offset-2"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="shadow sm:overflow-hidden sm:rounded-lg border-solid border-2 border-gray">
                                <div className="space-y-2 bg-white px-4 py-5 sm:p-6">
                                    {/* <div className="grid grid-cols-6 gap-6"> */}
                                    <div className="grid grid-cols-3 gap-x-2 gap-y-2 sm:grid-cols-3">
                                        <ProfilePic
                                            petdata={petdata}
                                            petid={petdata.id}
                                            type="petprimary"
                                            setImage={setImage}
                                            image={image}
                                        />

                                        <div className="sm:col-span-2 mt-4">
                                            <div className="flex-grow text-xl font-large">
                                                <div className="relative rounded-md shadow-sm">
                                                    <input
                                                        id="name"
                                                        type="text"
                                                        {...register('name', {
                                                            required: 'Pet name is required',
                                                            maxLength: {
                                                                value: 40,
                                                                message: 'Pet name cannot exceed 40 characters',
                                                            },
                                                        })}
                                                        aria-invalid={errors.name ? 'true' : 'false'}
                                                        autoComplete="petname"
                                                        className={errors.name ? errorInputClass : inputClass}
                                                    />
                                                    {errors.name ? (
                                                        <div className="pointer-events-none absolute inset-y-12 right-0 flex items-center pr-3">
                                                            <ExclamationCircleIcon
                                                                className="h-5 w-5 text-red-500"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                                {errors.name && (
                                                    <p
                                                        role="alert"
                                                        className="mt-2 text-sm text-red-600"
                                                        id="first-name-error"
                                                    >
                                                        {errors.name?.message}
                                                    </p>
                                                )}
                                            </div>
                                            <dt className="font-display flex items-center text-sm font-medium text-black mt-2">
                                                Message To Finder*{' '}
                                                <InformationCircleIcon
                                                    data-tooltip-id="finder-msg"
                                                    data-tooltip-content="This message will be displayed on the kiosk if your lost pet is found and scanned at the kiosk."
                                                    className="-mr-0.5 ml-0.5 h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                                <Tooltip
                                                    id="finder-msg"
                                                    place="right"
                                                    border="1px solid #36A5FF"
                                                    style={{
                                                        backgroundColor: 'rgb(255, 255, 255)',
                                                        width: '30%',
                                                        color: '#3092e3',
                                                    }}
                                                />
                                            </dt>

                                            <dd className="mt-1 mb-5 text-sm text-gray-900">
                                                <textarea
                                                    id="findermessage"
                                                    placeholder="Buster is the light of my life and my best friend. Thank you for finding Buster and helping them come home to me."
                                                    {...register('findermessage', {
                                                        required: 'Finder Message is required',
                                                        maxLength: {
                                                            value: 1000,
                                                            message: 'Finder Message cannot exceed 1000 characters',
                                                        },
                                                    })}
                                                    maxLength="128"
                                                    rows={3}
                                                    className={errors.findermessage ? errorInputClass : inputClass}
                                                    defaultValue={''}
                                                />
                                                {errors.findermessage ? (
                                                    <div className="pointer-events-none absolute right-8 flex items-center pr-3"></div>
                                                ) : null}
                                            </dd>
                                            {errors.findermessage && (
                                                <p
                                                    role="alert"
                                                    className="mt-2 text-sm text-red-600"
                                                    id="first-findermessage-error"
                                                >
                                                    {errors.findermessage?.message}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="w-[90%] mx-auto border-b border-gray"></div>
                                    <div className=" px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-3 gap-x-2 gap-y-2 sm:grid-cols-3 lg:grid-cols-3">
                                            <div className="sm:col-span-1 sm:row-span-1">
                                                <span className="font-display flex-grow ml-10 text-xl font-large">
                                                    Pet Info
                                                </span>
                                            </div>
                                            <div className="sm:col-span-2 sm:row-span-2">
                                                <div className="sm:col-span-1">
                                                    <dt className="font-display text-sm font-medium text-black">
                                                        About*
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <textarea
                                                            placeholder="Buster is a love bug who hates car rides. The vacuum is his mortal enemy. I love to catch him sunbathing on the patio."
                                                            id="abouttext"
                                                            {...register('abouttext', {
                                                                required: 'About Text is required',
                                                            })}
                                                            maxLength="1000"
                                                            rows={3}
                                                            className={errors.abouttext ? errorInputClass : inputClass}
                                                            defaultValue={''}
                                                        />
                                                        {errors.abouttext ? (
                                                            <div className="pointer-events-none absolute right-8 flex items-center pr-3"></div>
                                                        ) : null}
                                                    </dd>
                                                    {errors.abouttext && (
                                                        <p
                                                            role="alert"
                                                            className="mt-2 text-sm text-red-600"
                                                            id="first-abouttext-error"
                                                        >
                                                            {errors.abouttext?.message}
                                                        </p>
                                                    )}
                                                </div>

                                                <div className="sm:col-span-1 sm:row-span-2">
                                                    {
                                                        <>
                                                            <dt className="font-display text-sm font-medium text-black mt-6">
                                                                <span className="flex-grow">Birthdate*</span>
                                                            </dt>
                                                            <dd className="mt-1 text-sm  text-gray-900">
                                                                <div className="relative rounded-md shadow-sm">
                                                                    <input
                                                                        id="birthdate"
                                                                        type="date"
                                                                        {...register('birthdate', {
                                                                            required: 'Birthdate is required',
                                                                        })}
                                                                        className={
                                                                            errors.birthdate && errors.birthdate
                                                                                ? errorInputClass
                                                                                : inputClass
                                                                        }
                                                                    />
                                                                    {errors.birthdate && (
                                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                                            <ExclamationCircleIcon
                                                                                className="h-5 w-5 text-red-500"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                {errors.birthdate && (
                                                                    <p
                                                                        role="alert"
                                                                        className="mt-2 text-sm text-red-600"
                                                                        id="birthdate-error"
                                                                    >
                                                                        {errors.birthdate?.message}
                                                                    </p>
                                                                )}
                                                            </dd>
                                                            <div className="col-span-1">
                                                                <div className="flex relative rounded-md">
                                                                    <input
                                                                        type="checkbox"
                                                                        {...register('birthdateisapproximate')}
                                                                        className="mt-3 block border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                    />
                                                                    {errors.birthdateisapproximate && (
                                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                                            <ExclamationCircleIcon
                                                                                className="h-5 w-5 text-red-500"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    <label
                                                                        htmlFor="birthdateisapproximate"
                                                                        className="font-display block text-sm font-medium text-gray-700 mt-3 ml-3"
                                                                    >
                                                                        Approximate
                                                                    </label>
                                                                </div>
                                                                {errors.birthdateisapproximate && (
                                                                    <p
                                                                        role="alert"
                                                                        className="mt-2 text-sm text-red-600"
                                                                        id="street-address-error"
                                                                    >
                                                                        {errors.birthdateisapproximate?.message}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        <>
                                                            <dt className="font-display flex text-sm font-large text-black mt-6">
                                                                <span className="flex-grow">Pets Lifestage*</span>
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                <div>
                                                                    <select
                                                                        id="lifestage"
                                                                        {...register('lifestage', {
                                                                            required: 'Lifestage is required',
                                                                        })}
                                                                        className={
                                                                            errors.lifestage
                                                                                ? errorInputClass
                                                                                : inputClass
                                                                        }
                                                                    >
                                                                        <option value="Kitten/Puppy">
                                                                            Kitten/Puppy
                                                                        </option>
                                                                        <option value="Young Adult">Young Adult</option>
                                                                        <option value="Mature Companion">
                                                                            Mature Companion
                                                                        </option>
                                                                        <option value="Senior">Senior</option>
                                                                    </select>
                                                                    {errors.lifestage && (
                                                                        <div className="pointer-events-none absolute right-0 flex items-center pr-3"></div>
                                                                    )}
                                                                </div>
                                                                {errors.lifestage && (
                                                                    <p
                                                                        role="alert"
                                                                        className="mt-2 text-sm text-red-600"
                                                                        id="lifestage-error"
                                                                    >
                                                                        {errors.lifestage?.message}
                                                                    </p>
                                                                )}
                                                            </dd>
                                                        </>
                                                    }
                                                    {
                                                        <>
                                                            <dt className="font-display flex text-sm font-medium text-black mt-6">
                                                                <span className="flex-grow">Gender*</span>
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                <div className="relative rounded-md shadow-sm">
                                                                    <select
                                                                        {...register('sex', {
                                                                            required: 'Pet gender is required',
                                                                        })}
                                                                        id="sex"
                                                                        className={
                                                                            errors.sex ? errorInputClass : inputClass
                                                                        }
                                                                        defaultValue="none"
                                                                    >
                                                                        <option value="Female">Female</option>
                                                                        <option value="Male">Male</option>
                                                                    </select>
                                                                    {errors.sex ? (
                                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                                            <ExclamationCircleIcon
                                                                                className="h-5 w-5 text-red-500"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                                {errors.sex && (
                                                                    <p
                                                                        role="alert"
                                                                        className="mt-2 text-sm text-red-600"
                                                                        id="last-name-error"
                                                                    >
                                                                        {errors.sex?.message}
                                                                    </p>
                                                                )}
                                                            </dd>
                                                        </>
                                                    }
                                                    <ChipInfo petdata={petdata} />
                                                    <SectionPhotos
                                                        isEditPage={true}
                                                        imageurlone={image || petdata.imageurlone}
                                                        imagetitletwo={imagetwo || petdata.imageurltwo}
                                                        setOpen={setOpen}
                                                        setOpenType={setOpenType}
                                                    />
                                                </div>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>
        </>
    );
}

export default PetProfileEdit;
