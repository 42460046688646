import React, { useState } from 'react';
import { Collapse, Card, Row, Col } from 'antd';
import './Payment.css';
// import StripeCard from './StripeCard';
import CreditCard from './CreditCard';

const { Panel } = Collapse;

const Payments = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [creditCards, setCreditCards] = useState([
        { id: 0, number: 1234, isActive: true, isDefault: true, Expires: '5/25' },
        { id: 1, number: 5678, isActive: false, isDefault: false, Expires: '7/28' },
    ]);

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    const handleRemoveCard = (id) => {
        setCreditCards(creditCards.filter((card) => card.id !== id));
    };

    return (
        <div className="wrapper overflow-hidden bg-white shadow sm:rounded-lg border-solid border-2 border-gray">
            <Collapse defaultActiveKey={['1']} className="container overflow-hidden bg-white border-white">
                <Panel header="Your subscription expires: 12/31/2024." key="1" style={{ width: '100%' }}>
                    <Row gutter={32} alignment="top">
                        <Col flex="600px" style={{ transition: 'all 0.3s ease' }}>
                            <Card className="credit-card-container" title="Payment methods" bordered={false}>
                                {creditCards.map((card) => (
                                    <CreditCard
                                        key={card.id}
                                        id={card.id}
                                        isActive={card.isActive}
                                        isDefault={card.isDefault}
                                        number={card.number}
                                        Expires={card.Expires}
                                        onRemove={handleRemoveCard}
                                    />
                                ))}
                                <button
                                    type="button"
                                    onClick={handleEditClick}
                                    className="inline-flex justify-center items-center gap-x-2 rounded-md border border-ctcdarkblue py-2 px-4 text-sm font-medium text-ctcdarkblue shadow-sm hover:bg-ctcblack-100 hover:text-white focus:ring-ctcblue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ctcdarkblue w-full"
                                >
                                    {isEditing ? 'Cancel' : 'Edit'}
                                </button>
                            </Card>
                        </Col>
                        {/* {isEditing && (
                            <Col flex="auto" style={{ transition: 'all 0.3s ease' }}>
                                <StripeCard />
                            </Col>
                        )} */}
                    </Row>
                </Panel>
            </Collapse>
        </div>
    );
};

export default Payments;
