import React, { useEffect } from "react";
import { useDebounce } from "../utility/hooks";
import { useIsFirstRender } from "../utility/hooks";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import { PhoneIcon } from "@heroicons/react/20/solid";
import { useForm } from "react-hook-form";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import axios from "axios";

const SMARTY_KEY = process.env.REACT_APP_SMARTY_KEY;
const SMARTY_URL = process.env.REACT_APP_SMARTY_URL;
const phoneRegExp = /^(1\s?)?(\d{3}|\(\d{3}\))[\s-]?\d{3}[\s-]?\d{4}$/gm;

function buildAddress(addr) {
  return `${addr.street_line} ${addr.city}, ${addr.state} ${addr.zipcode}`;
}

export default function UserForm({ userdata, onSubmitChanges, serverError }) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({ defaultValues: userdata });

  const onSubmit = (data, event) => {
    data.location.street1 = document.getElementById(
      "us-autocomplete-pro-address-input",
    ).value;
    data.location.street2 = document.getElementById("street2").value;
    data.location.city = document.getElementById("city").value;
    data.location.state = document.getElementById("state").value;
    data.location.zip = document.getElementById("zip").value;
    onSubmitChanges(data);
  };

  useEffect(() => {
    const onServerError = (field, message) => {
      setError(field, { type: "custom", message });
    };
    if (serverError) {
      onServerError("email", serverError);
    }
  }, [serverError, setError]);

  const onError = (errors, e) => {
    console.error({ errors }, { e });
    toast.error("Form is invalid, please check your entries");
  };

  const is_first_render = useIsFirstRender();
  const [suggestions, setSuggestions] = useState([]);
  const [isHideSuggs, setIsHideSuggs] = useState(false);
  const [selectedVal, setSelectedVal] = useState(
    userdata.location?.street1 || "",
  );

  // ✅ Use debounce hook to debounced searchTerm as it is rapidly changing
  const debouncedSearchTerm = useDebounce(selectedVal, 500);

  useEffect(() => {
    const getSuggestions = async () => {
      try {
        const url =
          `${SMARTY_URL}?key=${SMARTY_KEY}&search=` + debouncedSearchTerm;

        const response = await axios.get(url, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          },
        });
        if (response.data.suggestions) {
          setSuggestions(response.data.suggestions);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (debouncedSearchTerm && !is_first_render) {
      getSuggestions();
    }
  }, [debouncedSearchTerm, is_first_render]);

  /* 
        city: "Fremont"
        entries: 0
        secondary: ""
        state: "CA"
        street_line: "30 Eagle Grn"
        zipcode: "94538" 
    */
  const hideSuggs = (sugg) => {
    setSelectedVal(
      sugg.street_line + " " + (sugg.secondary ? sugg.secondary : ""),
    );
    document.getElementById("city").value = sugg.city;
    document.getElementById("state").value = sugg.state;
    document.getElementById("zip").value = sugg.zipcode;
    setIsHideSuggs(true);
  };

  function searchAddr(event) {
    const searchStr = event.target.value;
    if (searchStr) {
      setIsHideSuggs(false);
    } else {
      const menu = document.getElementById("us-autocomplete-pro-menu");
      menu?.hide();
    }
    setSelectedVal(searchStr);
  }

  const inputClass =
    "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm";
  const errorInputClass =
    "mt-1 block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm";
  return (
    <>
      <div>
        {/* <PhotoUploadModal open={open} setOpen={setOpen} userid={userdata.id} image={image} setImage={setImage} /> */}
        <div className="md:grid md:grid-cols-2 md:gap-6">
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-ctcdarkblue mr-4 py-2 px-4 text-sm font-medium text-ctcdarkblue shadow-sm hover:bg-ctcblack-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-ctcdarkblue focus:ring-offset-2"
                >
                  Save Changes
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    navigate(-1);
                  }}
                  className="rounded-md border border-ctcdarkblue bg-white mr-2 py-2 px-4 text-sm font-medium text-ctcdarkblue shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-ctcdarkblue focus:ring-offset-2"
                >
                  Cancel
                </button>
              </div>
              <div className="shadow sm:overflow-hidden sm:rounded-lg border-solid border-2 border-gray">
                <div className="space-y-2 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="font-display block text-sm font-semibold"
                      >
                        First name*
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <input
                          type="text"
                          {...register("firstname", {
                            required: "First name is required",
                            maxLength: {
                              value: 40,
                              message: "First name cannot exceed 40 characters",
                            },
                          })}
                          aria-invalid={errors.firstname ? "true" : "false"}
                          autoComplete="given-name"
                          className={
                            errors.firstname ? errorInputClass : inputClass
                          }
                        />
                        {errors.firstname ? (
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon
                              className="h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          </div>
                        ) : null}
                      </div>
                      {errors.firstname && (
                        <p
                          role="alert"
                          className="mt-2 text-sm text-red-600"
                          id="first-name-error"
                        >
                          {errors.firstname?.message}
                        </p>
                      )}
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="font-display block text-sm font-semibold"
                      >
                        Last name*
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <input
                          type="text"
                          {...register("lastname", {
                            required: "Last name is required",
                            maxLength: {
                              value: 40,
                              message: "Last name cannot exceed 40 characters",
                            },
                          })}
                          autoComplete="family-name"
                          className={
                            errors.lastname ? errorInputClass : inputClass
                          }
                        />
                        {errors.lastname ? (
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon
                              className="h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          </div>
                        ) : null}
                      </div>
                      {errors.lastname && (
                        <p
                          role="alert"
                          className="mt-2 text-sm text-red-600"
                          id="last-name-error"
                        >
                          {errors.lastname?.message}
                        </p>
                      )}
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="street-address"
                        className="font-display block text-sm font-semibold"
                      >
                        Street address*
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <input
                          type="text"
                          onChange={searchAddr}
                          id="us-autocomplete-pro-address-input"
                          autoComplete="street-address"
                          value={selectedVal}
                          name="location.street1"
                          placeholder="Enter Address"
                          required
                          className={
                            errors.location && errors.location.street1
                              ? errorInputClass
                              : inputClass
                          }
                        />
                        {errors.location && errors.location.street1 && (
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon
                              className="h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          </div>
                        )}
                      </div>
                      {errors.location && errors.location.street1 && (
                        <p
                          role="alert"
                          className="mt-2 text-sm text-red-600"
                          id="street-address-error"
                        >
                          {errors.location.street1?.message}
                        </p>
                      )}
                      <ul
                        className="us-autocomplete-pro-menu"
                        style={{ display: isHideSuggs ? "none" : "block" }}
                      >
                        {" "}
                        {suggestions.map((item, idx) => (
                          <li
                            key={"" + item + idx}
                            onClick={() => {
                              hideSuggs(item);
                            }}
                          >
                            {buildAddress(item)}
                            {/* {item.street_line + item.secondary + " " + item.city + " " + item.state + " " + item.zipcode} */}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="col-span-6">
                      <div className="relative rounded-md shadow-sm">
                        <input
                          type="text"
                          id="street2"
                          {...register("location.street2", {})}
                          placeholder="Apartment number"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="col-span-6  sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="city"
                        className="font-display block text-sm font-semibold"
                      >
                        City*
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <input
                          type="text"
                          id="city"
                          disabled
                          {...register("location.city", {
                            // required: "City is required",
                            maxLength: {
                              value: 200,
                              message: "City cannot exceed 200 characters",
                            },
                          })}
                          autoComplete="address-level2"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        {errors.location && errors.location.city && (
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon
                              className="h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          </div>
                        )}
                      </div>
                      {errors.location && errors.location.city && (
                        <p
                          role="alert"
                          className="mt-2 text-sm text-red-600"
                          id="street-address-error"
                        >
                          {errors.location && errors.location.city?.message}
                        </p>
                      )}
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="region"
                        className="font-display block text-sm font-semibold"
                      >
                        State / Province*
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <input
                          type="text"
                          id="state"
                          disabled
                          {...register("location.state", {
                            // required: "State is required",
                            maxLength: {
                              value: 50,
                              message: "State cannot exceed 50 characters",
                            },
                          })}
                          autoComplete="address-level1"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        {errors.location && errors.location.state && (
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon
                              className="h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          </div>
                        )}
                      </div>
                      {errors.loccation && errors.loccation.state && (
                        <p
                          role="alert"
                          className="mt-2 text-sm text-red-600"
                          id="street-address-error"
                        >
                          {errors.location.state?.message}
                        </p>
                      )}
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal-code"
                        className="font-display block text-sm font-semibold"
                      >
                        ZIP / Postal code*
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <input
                          type="text"
                          id="zip"
                          disabled
                          {...register("location.zip", {
                            // required: "Zip is required",
                            maxLength: {
                              value: 12,
                              message: "Zip cannot exceed 12 characters",
                            },
                          })}
                          autoComplete="postal-code"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        {errors.location && errors.location.zip && (
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon
                              className="h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          </div>
                        )}
                      </div>
                      {errors.location && errors.location.zip && (
                        <p
                          role="alert"
                          className="mt-2 text-sm text-red-600"
                          id="street-address-error"
                        >
                          {errors.location.zip?.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="md:grid md:grid-cols-4 md:gap-3 mt-3">
                    <div className="mt-5 md:col-span-2 md:mt-3">
                      <label
                        htmlFor="phonenumber"
                        className="font-display block text-sm font-semibold"
                      >
                        Phone*
                      </label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <PhoneIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="relative rounded-md shadow-sm">
                          <input
                            type="tel"
                            {...register("phonenumber", {
                              required: "Phone is required",
                              pattern: {
                                value: phoneRegExp,
                                message: "Phone number is not valid",
                              },
                            })}
                            className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="111-111-1111"
                          />
                          {errors.phonenumber && (
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                        {errors.phonenumber && (
                          <p
                            role="alert"
                            className="mt-2 text-sm text-red-600"
                            id="phone-number-error"
                          >
                            {errors.phonenumber?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-3">
                      <label
                        htmlFor="phonetype"
                        className="md:col-span-1 font-display block text-sm font-semibold"
                      >
                        Phone Type
                      </label>
                      <label
                        htmlFor="phonetype"
                        className="md:col-span-1 mt-3 font-display block text-md"
                      >
                        CELL
                      </label>
                    </div>
                  </div>
                  <div className="md:grid md:grid-cols-4 md:gap-3">
                    <div className="mt-5 md:col-span-2 md:mt-3">
                      <label
                        htmlFor="email"
                        className="font-display flex text-sm font-semibold"
                      >
                        Email*
                        <InformationCircleIcon
                          data-tooltip-id="primary-email-msg"
                          data-tooltip-content="To update your primary email, contact us at support@checkthechip.com"
                          className="-mr-0.5 ml-0.5 h-5 w-5"
                          aria-hidden="true"
                        />
                        <Tooltip
                          id="primary-email-msg"
                          place="top"
                          border="1px solid #36A5FF"
                          style={{
                            backgroundColor: "rgb(255, 255, 255)",
                            width: "30%",
                            color: "#3092e3",
                          }}
                        />
                      </label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <EnvelopeIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="relative rounded-md shadow-sm">
                          <input
                            type="email"
                            disabled
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Invalid email address",
                              },
                            })}
                            className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-gray-200"
                            placeholder="primary@example.com"
                          />
                          {errors.email && (
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                        {errors.email && (
                          <p
                            role="alert"
                            className="mt-2 text-sm text-red-600"
                            id="email-error"
                          >
                            {errors.email?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="mt-3">
                      <label
                        htmlFor="emailtype"
                        className="md:col-span-1 font-display block text-sm font-semibold"
                      >
                        Email Type
                      </label>
                      <select
                        {...register("emailtype")}
                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        defaultValue="none"
                      >
                        <option value="PERSONAL">Personal</option>
                        <option value="WORK">Work</option>
                        <option value="SCHOOL">School</option>
                        <option value="OTHER">Other</option>
                      </select>
                    </div>

                    <div className="mt-5 md:col-span-2 md:mt-0">
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <EnvelopeIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="relative rounded-md shadow-sm">
                          <input
                            type="email"
                            {...register("email2", {
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Invalid email address",
                              },
                            })}
                            className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="secondary@example.com"
                          />
                          {errors.email2 && (
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                        {errors.email2 && (
                          <p
                            role="alert"
                            className="mt-2 text-sm text-red-600"
                            id="email-2-error"
                          >
                            {errors.email2?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div>
                      <select
                        {...register("emailtype2")}
                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        defaultValue="none"
                      >
                        <option value="PERSONAL">Personal</option>
                        <option value="WORK">Work</option>
                        <option value="SCHOOL">School</option>
                        <option value="OTHER">Other</option>
                      </select>
                    </div>

                    <div className="mt-5 md:col-span-2 md:mt-0">
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <EnvelopeIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="relative rounded-md shadow-sm">
                          <input
                            type="email"
                            {...register("email3", {
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Invalid email address",
                              },
                            })}
                            className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="third@example.com"
                          />
                          {errors.email3 && (
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                        {errors.email3 && (
                          <p
                            role="alert"
                            className="mt-2 text-sm text-red-600"
                            id="email-3-error"
                          >
                            {errors.email3?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div>
                      <select
                        {...register("emailtype3")}
                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        defaultValue="none"
                      >
                        <option value="PERSONAL">Personal</option>
                        <option value="WORK">Work</option>
                        <option value="SCHOOL">School</option>
                        <option value="OTHER">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="space-y-2 bg-white px-4 py-5 sm:p-6"></div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        {/* <div className="py-5">
                <div className="border-t border-gray-200" />
            </div> */}
      </div>
    </>
  );
}
